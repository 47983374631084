import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import PopUpContext from '../../helpers/PopUpContext'
import axios from 'axios'
import HelpersContext from '../../helpers/GlobalHelpers'
import toast from 'react-hot-toast'

function ConfrimTA() {

    const { setConfirmTA, chosenUser, setConfirmPWRelogin } = useContext(PopUpContext)
    const { setUserList, setInitialUserState, URI, currentUserInfo } = useContext(HelpersContext)

    const setTestAccount = async () => {
        const response = await axios.post(`${URI}setMeta`, { accessToken: currentUserInfo.accessToken , loginId: chosenUser.loginId , isTestUser: chosenUser._isTestUser === true ? "delete" : "true"})
        if(!response.data._isError) {
            setConfirmTA(false)
            setUserList(prev => prev.map(u => u.loginId === chosenUser.loginId ? {...u, _isTestUser: chosenUser._isTestUser === true ? null : true} : u))
            setInitialUserState(prev => prev.map(u => u.loginId === chosenUser.loginId ? {...u, _isTestUser: chosenUser._isTestUser === true ? null : true} : u))
            if(chosenUser?._isTestUser === true) {
                toast.success('Successfully Removed Test User')
            } else {
                toast.success('Successfully Set Test User')
            }
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setConfirmTA(false)
                setConfirmPWRelogin(true)
            } else {
                toast.error(`Oopps! ${response.data.reason}`)
                setConfirmTA(false)
            }
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
                <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto'>
                        <div className='w-full flex items-center justify-center flex-col px-4'>
                            <div className='w-full flex items-end justify-end relative border-b mb-4' onClick={() => setConfirmTA(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className='flex items-center mt-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-20 h-20 mr-6 ${chosenUser?._isTestUser === true ? `text-red-500` : `text-orange-500`}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <div className='flex flex-col'>
                                    {chosenUser?._isTestUser === true ? <p>Are you sure you want to unset</p> : <p>Are You sure you want to set</p> }
                                    <p className='text-xl my-2'><strong>{chosenUser?.lastName}, {chosenUser?.firstName}</strong></p>
                                    <h1 className='text-lg'>Test Account</h1>
                                </div>
                            </div>
                            <div className='mt-6 flex ml-10'>
                                <button className='px-3 py-2 bg-slate-700 hover:bg-slate-800 text-slate-200 rounded-lg mr-2' onClick={() => setConfirmTA(false)}>Cancel</button>
                                <button className='px-3 py-2 bg-blue-600 hover:bg-blue-700 text-slate-200 rounded-lg' onClick={() => {
                                    setTestAccount()
                                }}>Confirm</button>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
    )
}

export default ConfrimTA