import React from 'react'
import { Doughnut } from 'react-chartjs-2'

function DoughnutChartSource({sourceChartData}) {

  const options = {
    color: 'white',
    plugins: {
        legend: {
        labels: {
          generateLabels: function(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              const {
                labels: {
                  pointStyle
                }
              } = chart.legend.options;

              // const max = data.datasets[0].data.reduce((a, b) => (a + b), 0);

              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);

                return {
                  text: `${label} (${data.datasets[0].data[i]})`,
                  fillStyle: style.backgroundColor,
                  strokeStyle: style.borderColor,
                  lineWidth: style.borderWidth,
                  pointStyle: pointStyle,
                  hidden: !chart.getDataVisibility(i),

                  index: i
                };
              });
            }
            return [];
          }
        },
      },       
      tooltip: {
          callbacks: {
              label: function(context) {
                const max = context.dataset.data.reduce((a, b) => (a + b), 0);
                return `${context.label} (${Math.round(context.parsed * 100 / max)}%)`
        //         
              }
          }
      }
    },
  }

  //   const options = {
  //   color: 'white',
  //   plugins: {
  //       legend: {
  //       labels: {
  //         generateLabels: function(chart) {
  //           const data = chart.data;
  //           if (data.labels.length && data.datasets.length) {
  //             const {
  //               labels: {
  //                 pointStyle
  //               }
  //             } = chart.legend.options;

  //             const max = data.datasets[0].data.reduce((a, b) => (a + b), 0);

  //             return data.labels.map((label, i) => {
  //               const meta = chart.getDatasetMeta(0);
  //               const style = meta.controller.getStyle(i);

  //               return {
  //                 text: `${label} (${Math.round(data.datasets[0].data[i] * 100 / max)}%)`,
  //                 fillStyle: style.backgroundColor,
  //                 strokeStyle: style.borderColor,
  //                 lineWidth: style.borderWidth,
  //                 pointStyle: pointStyle,
  //                 hidden: !chart.getDataVisibility(i),

  //                 index: i
  //               };
  //             });
  //           }
  //           return [];
  //         }
  //       },
  //     }
  //   },
  // }


  return (
    <div className='xl:w-96 lg:w-80 md:w-64'>
        <Doughnut data={sourceChartData} options={options}/>
    </div>
  )
}

export default DoughnutChartSource