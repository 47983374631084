import React from 'react'

function ResolveTable() {
  return (
    <div className='w-full flex items-center justify-center text-white pb-6'>
        <h1>No data to show...</h1>
    </div>
  )
}

export default ResolveTable