import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import PopUpContext from '../../helpers/PopUpContext'

function ViewEscalationModal() {

    const { setIsPopUpOpen, currentTicket } = useContext(PopUpContext)

    return (
        <div className='min-w-screen min-h-screen z-50 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 w-3/4'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg" onClick={() => setIsPopUpOpen(false)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex items-center mb-6'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mr-2 text-blue-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            <h1 className='text-2xl font-bold'>Escalation Details</h1>
                        </div>
                        <div className="w-full space-y-6">
                            <table className='w-full'>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2 bg-slate-300'>
                                            <strong>Date:</strong> {currentTicket?.date}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2'>
                                            <strong>Customer Name:</strong> {currentTicket?.customer?.customerName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2 bg-slate-300'>
                                            <strong>Customer #:</strong> {currentTicket?.customer?.customerPhone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2'>
                                            <strong>Alternate #:</strong> {currentTicket?.customer?.customerAlternatePhone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2 bg-slate-300'>
                                            <strong>Issue:</strong> {currentTicket?.customer?.customerIssue}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2'>
                                            <strong>Agent Name:</strong> {currentTicket?.agent?.agentName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2 bg-slate-300'>
                                            <strong>Action Taken:</strong> {currentTicket?.actionTaken}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-b border-slate-300 p-2'>
                                            <strong>Resolution:</strong> {currentTicket?.resolution}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default ViewEscalationModal
