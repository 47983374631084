import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import toast from 'react-hot-toast';
import HelpersContext from '../../../helpers/GlobalHelpers';
import PopUpContext from '../../../helpers/PopUpContext';

function Confrim() {

    const { setAdminPopUp, setConfirmPWRelogin } = useContext(PopUpContext)
    const { currentUserGroup, setPermissions, confirmType, URI, currentUserInfo } = useContext(HelpersContext)

    const fetchPermission = async () => {
        const response = await axios.get(`${URI}getPermissions?accessToken=${currentUserInfo.accessToken}`)
            if (!response.data._isError) {
                setPermissions(response.data.permissions)
            } else {
            toast.error(response.data.reason)
        }
    }

    const handleRemovePermission = async (val) => {
        const response = await axios.post(`${URI}setPermission`, {accessToken: currentUserInfo.accessToken, type: 'permission', category: 'suspended', value: val.emp_id, name: val.value })
        if(!response.data._isError) {
            setAdminPopUp(false)
            toast.success('Removed from Users permission')
            fetchPermission()
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setAdminPopUp(false)
                setConfirmPWRelogin(true)
            } else {
                setAdminPopUp(false)
                toast.error(`Oopps! ${response.data.reason}`)
            }
        }
    }

    const handleSetAdmin = async (val) => {
        const response = await axios.post(`${URI}setPermission`, {accessToken: currentUserInfo.accessToken, type: 'permission', category: 'admins', value: val.emp_id, name: val.value })
        if(!response.data._isError) {
            setAdminPopUp(false)
            toast.success('Added To Admins')
            fetchPermission()
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setAdminPopUp(false)
                setConfirmPWRelogin(true)
            } else {
                setAdminPopUp(false)
                toast.error(`Oopps! ${response.data.reason}`)
            }
        }
    }
    
    const handleRemoveSuspension = async (val) => {
        const response = await axios.post(`${URI}setPermission`, {accessToken: currentUserInfo.accessToken, type: 'permission', category: 'users', value: val.emp_id, name: val.value })
        if(!response.data._isError) {
            setAdminPopUp(false)
            toast.success('Removed from Suspended Users')
            fetchPermission()
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setAdminPopUp(false)
                setConfirmPWRelogin(true)
            } else {
                setAdminPopUp(false)
                toast.error(`Oopps! ${response.data.reason}`)
            }
        }
    }

    const handleRemoveGroupPermission = async (val) => {
        const response = await axios.post(`${URI}setPermission`, {accessToken: currentUserInfo.accessToken, type: 'permission', category: 'suspended_groups', value: val.group_id, name: val.group_name })
        if(!response.data._isError) {
            setAdminPopUp(false)
            toast.success('Removed group from Permissions')
            fetchPermission()
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setAdminPopUp(false)
                setConfirmPWRelogin(true)
            }else {
                setAdminPopUp(false)
                toast.error(`Oopps! ${response.data.reason}`)
            }
        }
    }

    const handleRemoveGroupSuspension = async (val) => {
        const response = await axios.post(`${URI}setPermission`, {accessToken: currentUserInfo.accessToken, type: 'permission', category: 'groups', value: val.group_id, name: val.group_name })
        if(!response.data._isError) {
            setAdminPopUp(false)
            toast.success('Removed group from Permissions')
            fetchPermission()
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setAdminPopUp(false)
                setConfirmPWRelogin(true)
            }else {
                setAdminPopUp(false)
                toast.error(`Oopps! ${response.data.reason}`)
            }
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 mx-6'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative'  onClick={() => setAdminPopUp(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        {confirmType === 'users' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to remove User Permission of</p>
                                    <strong>{currentUserGroup.value}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemovePermission(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'admin' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to add Admin Permission to</p>
                                    <strong>{currentUserGroup.value}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleSetAdmin(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'suspended' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to remove Suspension for</p>
                                    <strong> {currentUserGroup.value}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemoveSuspension(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'setToUser' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to remove Admin Permission for</p>
                                    <strong>{currentUserGroup.value}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemoveSuspension(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'setToSuspended' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to remove Admin Permission & Suspend</p>
                                    <strong>{currentUserGroup.value}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemovePermission(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'groupToSuspend' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to remove Permission & Suspend group</p>
                                    <strong>{currentUserGroup?.group_name}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemoveGroupPermission(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                        {confirmType === 'groupToPermission' &&
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex flex-col items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-red-500 mb-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>                                     
                                <div className='w-full flex flex-col items-center'>
                                    <p>Do you want to Unsuspend and add Permission to group</p>
                                    <strong>{currentUserGroup?.group_name}</strong>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setAdminPopUp(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleRemoveGroupSuspension(currentUserGroup)}>Confirm</button>
                            </div>
                        </div>
                        }
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Confrim