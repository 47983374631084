import React from 'react'

function FallbackPage() {
  return (
    <div className='h-screen w-full bg-slate-800 flex items-center justify-center'>
        <div className='min-h-full w-full flex flex-col items-center justify-center'>
            <h2 className='text-9xl text-white mb-6'>404</h2>
            <h2 className='text-xl text-white'>Page Not Found.</h2>
        </div>
    </div>
  )
}

export default FallbackPage