import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  useSortBy,
  useTable,
  useGlobalFilter,
  usePagination,
} from "react-table";
import HelpersContext from "../../helpers/GlobalHelpers";
import { GlobalFilter } from "../../helpers/SearchFilter";
import moment from "moment";
import { motion } from "framer-motion";
import MiniMenu from "../MiniMenu";

function UserListTable() {
  const {
    userList,
    setUserList,
    initialUserState,
    totalUsers,
    isLoadingUserList,
  } = useContext(HelpersContext);
  const [userType, setUserType] = useState("all");
  const [subscriberList, setSubscriberList] = useState([]);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `User list ${moment(new Date()).format("DD-MMM-YYYY")}`,
    sheet: "Users",
  });

  function readableRegtag(regtag) {
    switch (regtag) {
      case "xroId":
        return "Xero";
      case "googleId":
        return "Google";
      case "msId":
        return "Microsoft";
      case "WcId":
        return "WC";
      case "aplId":
        return "Apple";
      default:
        return regtag;
    }
  }

  useEffect(() => {
    let newList = [];
    for (let user of userList) {
      let subsList = Object.values(user?.subscription?.subscriptionItems).map(
        (e) => Object.values(e)[0]
      );

      subsList.sort((a, b) => (a.status === "active" ? -1 : 1));
      let userObj = {
        id: user?.id,
        loginId: user?.loginId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        dateCreated: moment(user?.dateCreated).format("lll"),
        provider: user?.subscription?.provider,
        productId: user?.subscription?.productID,
        productName:
          subsList[0]?.status === "active" ? subsList[0]?.productName : "",
        subscriptionExpiry:
          subsList[0]?.subscriptionPeriodExpires === undefined
            ? moment(user?.subscription?.subscriptionPeriodExpires).format(
                "lll"
              )
            : moment(subsList[0]?.subscriptionPeriodExpires).format("lll"),
        mobilePhone: user?.mobilePhone,
        email: user?.email,
        _isTestUser: user?._isTestUser,
        regTag:
          user?.ssoRegTag !== null
            ? readableRegtag(Object.keys(user?.ssoRegTag)[0])
            : "",
      };

      newList.push(userObj);
    }
    setSubscriberList(newList);
  }, [userList, setUserList]);

  const data = useMemo(() => [...subscriberList], [subscriberList]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "loginId",
      },
      {
        Header: "Last name",
        accessor: "lastName",
      },
      {
        Header: "First name",
        accessor: "firstName",
      },

      {
        Header: "Provider",
        accessor: "provider",
      },
      {
        Header: "RegTag",
        accessor: "regTag",
      },
      {
        Header: "Product ID",
        accessor: "productId",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Subscription Expiry",
        accessor: "subscriptionExpiry",
      },
      {
        Header: "Mobile No.",
        accessor: "mobilePhone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "action",
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex text-xs items-start justify-evenly">
            <MiniMenu data={row} />
          </div>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const handleChange = () => {
    if (userType === "all") {
      setUserList(initialUserState);
    }
    if (userType === "active") {
      setUserList(initialUserState?.filter((u) => u?.dateActivated));
    }
    if (userType === "inactive") {
      setUserList(initialUserState?.filter((u) => u?._isActive !== true));
    }
    if (userType === "test") {
      setUserList(initialUserState?.filter((u) => u?._isTestUser === true));
    }
    if (userType === "inactivesub") {
      setUserList(
        initialUserState?.filter(
          (u) =>
            u?.subscription?.provider === "trial" ||
            u?.subscription?.productID === "freemium" ||
            u?.subscription?.provider === "complimentary"
        )
      );
    }
    if (userType === "activesub") {
      const activeSubUsers = initialUserState?.filter((u) => {
        const subsList = Object.values(u?.subscription?.subscriptionItems).map(
          (e) => Object.values(e)[0]
        );
        return subsList.some((item) => item?.status === "active");
      });

      setUserList(activeSubUsers);
    }
  };

  const keyString = "37693cfc748049e45d87b8c7d8b9aacd";
  function stringGen(len) {
    let text = "";

    for (let i = 0; i < len; i++)
      text += keyString.charAt(Math.floor(Math.random() * keyString.length));

    return text;
  }

  return (
    <>
      <div className="w-full flex flex-col">
        {!userList || userList?.length === 0 ? (
          <>
            <div className="w-full flex items-center justify-center my-4">
              <h1 className="text-white">Nothing to show</h1>
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col px-6 py-4">
            <div className=" flex md:flex-row-reverse flex-col items-center justify-between mb-2">
              <div className="flex">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  setGlobalFilter={setGlobalFilter}
                  globalFilter={state.globalFilter}
                />
              </div>
              <div className="flex items-center">
                <h2 className="text-slate-200">Show</h2>
                <select
                  name=""
                  id=""
                  className="rounded-md mx-2 py-1"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 25, 50, 100, `${subscriberList.length}`].map(
                    (pageSize) => (
                      <option key={stringGen(13)} value={pageSize}>
                        {pageSize}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mb-2">
              <button
                className="text-xs bg-cyan-600 hover:bg-cyan-700 text-slate-200 px-2 py-1 rounded-md"
                onClick={onDownload}
              >
                {" "}
                Export to excel{" "}
              </button>
              <div className="flex flex-row-reverse">
                <button
                  className="px-3 py-1 text-xs text-white bg-green-500 hover:bg-green-600 ml-2 rounded-md"
                  onClick={() => handleChange()}
                >
                  Filter
                </button>
                <select
                  name="userTypes"
                  id=""
                  className="rounded-md w-24 text-sm"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option className="text-sm" value="all">
                    All
                  </option>
                  <option className="text-sm" value="active">
                    Active Users
                  </option>
                  <option className="text-sm" value="inactive">
                    Inactive Users
                  </option>
                  <option className="text-sm" value="test">
                    Test Users
                  </option>
                  <option className="text-sm" value="activesub">
                    Active Subscription
                  </option>
                  <option className="text-sm" value="inactivesub">
                    Inactive Subscription
                  </option>
                </select>
              </div>
            </div>
            {isLoadingUserList && (
              <div className="w-full flex items-center justify-center text-white mb-4 relative">
                <div className="rounded animate-spin ease duration-300 w-5 h-5 border-2 border-white mr-3"></div>
                <h3>
                  <span className="animate-pulse">
                    <strong className="">{subscriberList.length} </strong>
                  </span>
                  / {totalUsers} fetching
                  <span className="animate-pulse">...</span>
                </h3>
              </div>
            )}
            <div className=" flex max-h-96 mb-4 overflow-y-scroll scrollbar-thumb-cyan-700 scrollbar-thin scrollbar-track-gray-100  scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded-md">
              <table
                ref={tableRef}
                className="w-full bg-slate-700 rounded-lg relative mr-2"
                {...getTableProps}
              >
                <thead className="sticky top-0 bg-slate-700">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          className="text-xs md:text-sm lg:text-base text-slate-300 py-4 hover:bg-slate-600"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <span className="ml-2 text-xs text-cyan-500">
                                  &#x25BC;
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="ml-2 text-xs text-cyan-500">
                                  &#9650;
                                </span>
                              </>
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-slate-900 text-slate-300"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);

                    return (
                      <tr
                        key={i}
                        className="border-b border-slate-700"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => (
                          <motion.td
                            key={i}
                            animate={{ opacity: [0, 1] }}
                            transition={{ duration: 0.3, delay: i * 0.1 }}
                            className={`py-4 px-2 text-xs md:text-md lg:text-base ${
                              cell.row.original._isTestUser !== null
                                ? `bg-slate-300 bg-opacity-80 text-slate-900`
                                : ""
                            }`}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </motion.td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex sm:flex-row flex-col items-center justify-between">
              <div className="text-slate-300">
                Page{" "}
                <strong className="text-2xl">
                  <span className="text-3xl text-slate-200 underline">
                    {pageIndex + 1}
                  </span>{" "}
                  of {pageOptions.length}
                </strong>{" "}
              </div>
              <div className="flex md:flex-row flex-col">
                <div className=" flex items-center justify-end md:mr-4 mr-0 md:mb-0 mb-2">
                  <span className="mr-2 text-slate-300">Go to page: </span>
                  <input
                    type="number"
                    className="w-14 py-1 rounded-md text-slate-900 pl-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </div>
                <div className="">
                  <button
                    className={`bg-slate-200 px-2 py-1 rounded-tl-md rounded-bl-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${
                      !canPreviousPage ? `cursor-not-allowed` : ""
                    }`}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>
                  <button
                    className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${
                      !canPreviousPage ? `cursor-not-allowed` : ""
                    }`}
                    disabled={!canPreviousPage}
                    onClick={() => previousPage()}
                  >
                    Prev
                  </button>
                  <button
                    className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${
                      !canNextPage ? `cursor-not-allowed` : ""
                    }`}
                    disabled={!canNextPage}
                    onClick={() => nextPage()}
                  >
                    Next
                  </button>
                  <button
                    className={`bg-slate-200 px-2 py-1 rounded-tr-md rounded-br-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${
                      !canNextPage ? `cursor-not-allowed` : ""
                    }`}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserListTable;
