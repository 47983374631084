import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import PopUpContext from '../../../../helpers/PopUpContext'

function AddNewApiURL() {

    const { setIsOpenAddAPI, setIsOpenConfirmAddAPI, inputFields, setInputFields } = useContext(PopUpContext)

    const addFields = () => {
        let newfield = { name: '', url: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    }

    const handleSubmit = (e) => {
        if(inputFields.length <= 0 ) {
            toast.error('Please provide proper api/url')
        } else {
            e.preventDefault()
            setIsOpenConfirmAddAPI(true)
            setIsOpenAddAPI(false)
        }
    }


    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => setIsOpenAddAPI(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                </svg>
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold mb-2'>Add new API URL</h1>
                                    <p>This will add a new API list.</p>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                                    <div className='w-full flex justify-end mb-2'>
                                        <button onClick={addFields} className='mt-4 px-2 py-1 rounded-md bg-green-500 hover:bg-green-600 text-xs text-white'>Create Field</button>
                                    </div>
                                <form onSubmit={(e) => handleSubmit(e)} action="" className='w-full'>
                                    {inputFields.map((input, index) => {
                                        return (
                                            <div key={index} className='flex items-center mb-2'>
                                                <label htmlFor="name" className='mr-2'>Name</label>
                                                <input
                                                    className='px-2 py-1 border border-slate-700 rounded-md mr-2'
                                                    name='name'
                                                    placeholder='Name'
                                                    value={input.name}
                                                    onChange={event => handleFormChange(index, event)}
                                                />
                                                <label htmlFor="url" className='mr-2'>URL</label>
                                                <input
                                                    className='px-2 py-1 border border-slate-700 rounded-md mr-2'
                                                    name='url'
                                                    placeholder='url'
                                                    value={input.url}
                                                    onChange={event => handleFormChange(index, event)}
                                                />
                                                <button onClick={(e) => {
                                                    e.preventDefault()
                                                    removeFields(index)
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 hover:scale-110 hover:text-red-600">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                    })}
                                    <div className='w-full flex items-center justify-center mt-4'>
                                        <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenAddAPI(false)}>Cancel</button>
                                        <button type='submit' className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={(e) => {
                                            e.preventDefault()
                                            handleSubmit(e)
                                        }}>Confirm</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default AddNewApiURL