import React, { useContext, useMemo, useRef } from 'react'
import { useDownloadExcel } from 'react-export-table-to-excel'
import { useSortBy, useTable, useGlobalFilter, usePagination } from 'react-table'
import HelpersContext from '../../helpers/GlobalHelpers'
import { GlobalFilter } from '../../helpers/SearchFilter'
import { motion } from 'framer-motion'
import moment from 'moment/moment'
import PopUpContext from '../../helpers/PopUpContext'

function AppEnviTable() {

    const { appEnvi, setChosenEnvi } = useContext(HelpersContext)
    const { setIsOpenAddEnvi, setIsOpenDelEnvi, setIsOpenUpdateEnvi } = useContext(PopUpContext)

    const tableRef = useRef(null)
    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: `Employee Users list ${moment(new Date()).format('DD-MMM-YYYY')}`,
        sheet: 'Users'
    })

    const data = useMemo(() => [...appEnvi], [appEnvi])

    const columns = useMemo(() => [
        {
            Header: 'Environment',
            accessor: 'value',
        },
    ],[])

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                id: "action",
                Header: 'Action',
                Cell: ({ row }) => (
                    <div className='flex justify-center'>
                        <button className='px-2 py-1 text-sm bg-green-600 hover:bg-green-700 text-white rounded-md md:mr-2 mr-0 md:mb-0 mb-2' onClick={() => {
                            setIsOpenUpdateEnvi(true)
                            setChosenEnvi(row.values)
                        }}>Update</button>
                        <button className='px-2 py-1 text-sm bg-red-600 hover:bg-red-700 text-white rounded-md md:mr-2 mr-0 md:mb-0 mb-2' onClick={() => {
                            setIsOpenDelEnvi(true)
                            setChosenEnvi(row.values)
                        }}>Remove</button>
                    </div>
                )
            }
        ])
    }


    const tableInstance = useTable({ columns, data}, useGlobalFilter, useSortBy, tableHooks,usePagination)

    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize
    } = tableInstance

    const { pageIndex, pageSize } = state

    const keyString = '37693cfc748049e45d87b8c7d8b9aacd'
    function stringGen(len) {
        let text = "";
        
        for (let i = 0; i < len; i++)
            text += keyString.charAt(Math.floor(Math.random() * keyString.length));
        
        return text;
    }

    return (
        <>
            <div className='w-full flex flex-col rounded-lg'>
                <div className='w-full flex flex-col px-6 py-4'>
                    <div className='w-full flex items-end justify-end mb-4'>
                        <button className='flex items-center text-white bg-blue-600 hover:bg-blue-700 px-2 py-1 rounded-md' onClick={() => setIsOpenAddEnvi(true)}>
                        New 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        </button>
                    </div>
                        {!appEnvi || appEnvi?.length === 0 ? 
                    <>
                        <div className='w-full flex items-center justify-center my-4'>
                            <h1 className='text-white'>Nothing to show</h1>
                        </div>
                    </> 
                    : 
                    <>
                        <div className=' flex md:flex-row-reverse flex-col items-center justify-between mb-2'>
                            <div className='flex'>
                                <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
                            </div>
                            <div className='flex items-center'>
                                <h2 className='mr-2 text-slate-200'>Show</h2>
                                <select name="" id="" className='rounded-md mx-2 py-1' value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                    {
                                        [10, 25, 50, 100, `${appEnvi?.length}`].map(pageSize => (
                                            <option key={stringGen(13)} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-row-reverse'>
                            <button className='mb-2 text-xs bg-cyan-600 hover:bg-cyan-700 text-slate-200 px-2 py-1 rounded-md' onClick={onDownload}> Export to excel </button>
                        </div>
                        <div className='w-full flex max-h-96 overflow-auto mb-4 scrollbar-thumb-cyan-700 scrollbar-thin scrollbar-track-gray-100  scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded-lg'>
                            <table ref={tableRef} className='w-full mr-2 bg-slate-700 rounded-lg relative' {...getTableProps}>
                                <thead className='sticky top-0 bg-slate-700'>
                                    {headerGroups.map((headerGroup, i) => (
                                        <tr key={i}  {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column, i) => (
                                                <th key={i} className='text-xs md:text-sm lg:text-base text-slate-300 py-4 hover:bg-slate-600' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    {column.isSorted ? (column.isSortedDesc ? 
                                                    <>
                                                        <span className='ml-2 text-xs text-cyan-500'>&#x25BC;</span></> 
                                                        : 
                                                    <>
                                                        <span className='ml-2 text-xs text-cyan-500'>&#9650;</span></>) 
                                                    : ""}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody className='bg-slate-900 text-slate-300 text-center' {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row)

                                        return  <tr key={i} className='border-b border-slate-700' {...row.getRowProps()}>
                                                    {row.cells.map((cell,i) => (
                                                        <motion.td key={i} animate={{ opacity: [0,1] }} transition={{ duration: 0.3, delay: i * 0.1 }} className='py-4 px-2 text-xs md:text-md lg:text-base' {...cell.getCellProps()}>{ cell.render("Cell") }</motion.td>
                                                    ))}
                                                </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='flex sm:flex-row flex-col items-center justify-between'>
                            <div className='text-slate-300'>
                                Page {' '}
                                <strong className='text-2xl'><span className='text-3xl text-slate-200 underline'>{pageIndex + 1}</span> of {pageOptions.length}</strong>
                                {' '}
                            </div>
                            <div className='flex md:flex-row flex-col'>
                                <div className=' flex items-center justify-end md:mr-4 mr-0 md:mb-0 mb-2'>
                                    <span className='mr-2 text-slate-300'>Go to page: {' '}</span>
                                    <input type="number" className='w-14 py-1 rounded-md text-slate-900 pl-2' defaultValue={pageIndex + 1} onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }} />   
                                </div>
                                <div className=''>
                                    <button className={`bg-slate-200 px-2 py-1 rounded-tl-md rounded-bl-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canPreviousPage ? `cursor-not-allowed` : ''}`}  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                    <button className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canPreviousPage ? `cursor-not-allowed` : ''}`} disabled={!canPreviousPage} onClick={() => previousPage()}>Prev</button>
                                    <button className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canNextPage ? `cursor-not-allowed` : ''}`} disabled={!canNextPage} onClick={() => nextPage()}>Next</button>
                                    <button className={`bg-slate-200 px-2 py-1 rounded-tr-md rounded-br-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canNextPage ? `cursor-not-allowed` : ''}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </> 
                    }
                </div>
            </div>
        </>
    )
}

export default AppEnviTable