import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import Pulse from '../../Components/Toast/Pulse'
import HelpersContext from '../../helpers/GlobalHelpers'
import PopUpContext from '../../helpers/PopUpContext'
import CustomerListContainer from './CustomerListContainer'
import EscalationListContainer from './EscalationListContainer'

function UserManagement() {

  const { isLoading } = useContext(HelpersContext)
  const [activeTab, setActiveTab] = useState('customer')
  const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
  const { URI } = useContext(HelpersContext)
  const { setConfirmPWRelogin } = useContext(PopUpContext)

  useEffect(() => {
      const getUserInfo = async () => {
          const response = await axios.post(`${URI}getUserlist?`, {accessToken: currentUserInfo?.accessToken})
          if(!response.data._isError) {
          } else {
              if(response.data?.isAuthorized?.valid === false) {
                  toast.error(response.data?.isAuthorized?.message)
                  setConfirmPWRelogin(true)
              }
          }
      }
      getUserInfo()
  },[currentUserInfo.accessToken, currentUserInfo.loginId, setConfirmPWRelogin, URI])

  return (
    <motion.div initial={{opacity:0, x:400}} animate={{opacity: 1, x:0}} exit={{opacity:0, x:-400}} transition={{duration: 1.2}} className='w-auto max-h-screen ml-4 overflow-y-auto'>
        <div className='w-auto h-auto flex flex-col mb-40'>
            <div className='pt-20'>
                <h1 className='pl-2 font-bold text-4xl text-blue-500'>User Management</h1>
            </div>
            <div className='mt-16 flex items-center text-slate-100'>
               <div className={activeTab === 'customer' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setActiveTab('customer')}>
                    <h2 className='text-lg'>Customer</h2>
               </div>
               <div className={activeTab === 'escalation' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setActiveTab('escalation')}>
                    <h2 className='text-lg'>Escalations</h2>
               </div>
            </div>
            {!isLoading ?
              <>
              { activeTab === 'customer' && <CustomerListContainer /> }
              { activeTab === 'escalation' && <EscalationListContainer /> }
              </>
            :
            <Pulse/>
            }
        </div>
    </motion.div>
  )
}

export default UserManagement