import axios from "axios";
import { motion } from "framer-motion";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import toast from "react-hot-toast";
import PopUpContext from "../../helpers/PopUpContext";
import Pulse from "../Toast/Pulse";

function ViewUserSubscription() {
  const [isLoading, setIsLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState({});
  const [tabState, setTabState] = useState("basic");
  const [isOpen, setIsOpen] = useState(false);
  const [subsItemsList, setSubsItemsList] = useState([]);
  const [showEntityDetails, setShowEntityDetails] = useState("");
  const [currentSubsItem, setCurrentSubsItem] = useState({});
  const { chosenUser, setIsViewSubscription } = useContext(PopUpContext);
  const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const server = localStorage.getItem("server");
    setIsLoading(true);
    const getSubsDetails = async () => {
      const response = await axios.post(
        `${
          server === "pre"
            ? "https://preprod-mapi.moolamore.com/supmgnt/utils/getSubscriptionById?"
            : "https://stage-mapi.moolamore.com/supmgnt/utils/getSubscriptionById?"
        }`,
        {
          accessToken: currentUserInfo.accessToken,
          loginId: chosenUser.loginId,
        }
      );
      if (!response.data._isError) {
        setUserSubscription(response.data);
        setCurrentSubsItem(
          Object.values(response.data.subscription?.subscriptionItems).map(
            (e) => Object.values(e)[0]
          )[0]
        );
        setSubsItemsList(
          Object.values(response.data.subscription?.subscriptionItems).map(
            (e) => Object.values(e)[0]
          )
        );
        setIsLoading(false);
      } else {
        toast.error(response.data.reason);
        setIsViewSubscription(false);
      }
    };
    getSubsDetails();
  }, [currentUserInfo.accessToken, chosenUser.loginId, setIsViewSubscription]);

  return (
    <div className="min-w-screen min-h-screen z-50 fixed flex items-center justify-center bg-black bg-opacity-40 mb-60">
      <div className="w-screen h-3/4 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ duration: 0.5 }}
          className="h-auto flex rounded-lg bg-slate-200 p-4 md:w-3/4 xl:1/2 w-3/4 pb-6"
        >
          <div className="w-full h-3/4 flex items-center justify-center flex-col">
            {isLoading ? (
              <Pulse />
            ) : (
              <div className="w-full flex flex-col">
                <div
                  className="w-full flex items-end justify-end relative "
                  onClick={() => setIsViewSubscription(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="w-full flex items-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12 mr-2 text-blue-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <h1 className="text-2xl font-bold">
                    User Subscription Details
                  </h1>
                </div>
                <div className="w-auto flex flex-1 overflow-x-auto mb-6">
                  <button
                    className={`px-2 border-r border-white text-white rounded-t-sm ${
                      tabState === "basic" ? "bg-blue-600" : "bg-slate-400"
                    }`}
                    onClick={() => setTabState("basic")}
                  >
                    Summary
                  </button>
                  <button
                    className={`px-2 border-r border-white text-white rounded-t-sm ${
                      tabState === "current" ? "bg-blue-600" : "bg-slate-400"
                    }`}
                    onClick={() => setTabState("current")}
                  >
                    Current Subscription
                  </button>
                  <button
                    className={`px-2 border-r border-white text-white rounded-t-sm ${
                      tabState === "previous" ? "bg-blue-600" : "bg-slate-400"
                    }`}
                    onClick={() => setTabState("previous")}
                  >
                    Previous Subscriptions
                  </button>
                  <button
                    className={`px-2 border-r border-white text-white rounded-t-sm ${
                      tabState === "entities" ? "bg-blue-600" : "bg-slate-400"
                    }`}
                    onClick={() => setTabState("entities")}
                  >
                    Entities / Companies
                  </button>
                  <button
                    className={`px-2 border-r border-white text-white rounded-t-sm ${
                      tabState === "userInformation"
                        ? "bg-blue-600"
                        : "bg-slate-400"
                    }`}
                    onClick={() => setTabState("userInformation")}
                  >
                    User Information
                  </button>
                </div>
                {tabState === "basic" && (
                  <div className="w-full space-y-6 mb-4 max-h-96 overflow-y-auto">
                    <table className="w-full">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Customer ID:</strong>{" "}
                            {userSubscription?.subscription?.customerId}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Status:</strong>{" "}
                            {userSubscription?.subscription?.status}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Product name:</strong>{" "}
                            {userSubscription?.subscription?.productID}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Subscription ID:</strong>{" "}
                            {userSubscription?.subscription?.id}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Date Start:</strong>{" "}
                            {moment(
                              userSubscription?.subscription
                                ?.subscriptionPeriodStarted
                            ).format("lll")}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Date Expiry:</strong>{" "}
                            {moment(
                              userSubscription?.subscription
                                ?.subscriptionPeriodExpires
                            ).format("lll")}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Provider:</strong>{" "}
                            {userSubscription?.subscription?.provider}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Max Entities:</strong>{" "}
                            {userSubscription?.subscription?.maxEntities === -1
                              ? "Unlimited"
                              : userSubscription?.subscription?.maxEntities}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {tabState === "current" && (
                  <>
                    {currentSubsItem?.status === "active" ? (
                      <div className="w-full space-y-6 mb-4 max-h-96 overflow-y-auto">
                        <table className="w-full">
                          <thead></thead>
                          <tbody>
                            {currentSubsItem?.id ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Subscription ID:
                                  </strong>{" "}
                                  {currentSubsItem?.id}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.product ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Product ID:</strong>{" "}
                                  {currentSubsItem?.product}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.productID ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Subscription Name:
                                  </strong>{" "}
                                  {currentSubsItem?.productID}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.provider ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Provider:</strong>{" "}
                                  {currentSubsItem?.provider}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.type ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Subscription Type:
                                  </strong>{" "}
                                  {currentSubsItem?.type}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.plan?.product ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Product ID:</strong>{" "}
                                  {currentSubsItem?.plan?.product}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.productName ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Product Name:
                                  </strong>
                                  {currentSubsItem?.productName}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.currency ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Coverage:</strong>{" "}
                                  {currentSubsItem?.currency}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.price ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Price:</strong>{" "}
                                  {currentSubsItem?.provider === "stripe"
                                    ? `$${(
                                        currentSubsItem?.price / 100
                                      ).toFixed(2)}`
                                    : `$${currentSubsItem.total}`}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.coverage ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Coverage:</strong>{" "}
                                  {currentSubsItem?.coverage}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.maxEntities ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Max Entities:
                                  </strong>{" "}
                                  {currentSubsItem?.maxEntities === -1
                                    ? "Unlimited"
                                    : currentSubsItem?.maxEntities}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.status ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Status:</strong>{" "}
                                  {currentSubsItem?.status}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.subscriptionPeriodStarted ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Subscription Start:
                                  </strong>{" "}
                                  {moment(
                                    currentSubsItem?.subscriptionPeriodStarted
                                  ).format("lll")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.subscriptionPeriodExpires ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Subscription Expiry:
                                  </strong>{" "}
                                  {moment(
                                    currentSubsItem?.subscriptionPeriodExpires
                                  ).format("lll")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.plan ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Currency:</strong>{" "}
                                  {currentSubsItem?.plan?.currency}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.plan ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Amount:</strong>{" "}
                                  {currentSubsItem?.plan?.amount !== 0
                                    ? (
                                        currentSubsItem?.plan?.amount / 100
                                      ).toFixed(2)
                                    : "-"}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.current_period_start ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">
                                    Period Start:
                                  </strong>{" "}
                                  {moment
                                    .utc(
                                      currentSubsItem?.current_period_start *
                                        1000
                                    )
                                    .format("lll")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {currentSubsItem?.current_period_end ? (
                              <tr>
                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                                  <strong className="w-1/4">Period End:</strong>{" "}
                                  {moment
                                    .utc(
                                      currentSubsItem?.current_period_end * 1000
                                    )
                                    .format("lll")}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-center mt-4">
                        <h1 className="text-slate-600">
                          No Current Subscription
                        </h1>
                      </div>
                    )}
                  </>
                )}
                {tabState === "previous" && (
                  <>
                    {subsItemsList?.filter(
                      (e) => e.id !== userSubscription?.subscription?.id
                    ).length > 0 ? (
                      <div className="w-full space-y-6 mb-4 max-h-96 overflow-y-auto">
                        {subsItemsList
                          ?.filter(
                            (e) => e.id !== userSubscription?.subscription?.id
                          )
                          .map((filteredPrev, i) => (
                            <div key={i}>
                              <table className="w-full">
                                <thead></thead>
                                <tbody>
                                  {filteredPrev?.id ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Subscription ID:
                                        </strong>{" "}
                                        {filteredPrev?.id}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.product ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Product ID:
                                        </strong>{" "}
                                        {filteredPrev?.product}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.productID ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Subscription Name:
                                        </strong>{" "}
                                        {filteredPrev?.productID}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.provider ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Provider:
                                        </strong>{" "}
                                        {filteredPrev?.provider}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.type ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Subscription Type:
                                        </strong>{" "}
                                        {filteredPrev?.type}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.plan?.product ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Product ID:
                                        </strong>{" "}
                                        {filteredPrev?.plan?.product}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.productName ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Product Name:
                                        </strong>
                                        {filteredPrev?.productName}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.currency ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Coverage:
                                        </strong>{" "}
                                        {filteredPrev?.currency}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.price ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Price:
                                        </strong>{" "}
                                        {filteredPrev?.price === "stripe"
                                          ? `$${(
                                              filteredPrev?.price / 100
                                            ).toFixed(2)}`
                                          : `$${filteredPrev?.price}`}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.coverage ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Coverage:
                                        </strong>{" "}
                                        {filteredPrev?.coverage}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.maxEntities ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Max Entities:
                                        </strong>{" "}
                                        {filteredPrev?.maxEntities === -1
                                          ? "Unlimited"
                                          : filteredPrev?.maxEntities}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.status ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Status:
                                        </strong>{" "}
                                        {filteredPrev?.status}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.subscriptionPeriodStarted ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Subscription Start:
                                        </strong>{" "}
                                        {moment(
                                          filteredPrev?.subscriptionPeriodStarted
                                        ).format("lll")}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.subscriptionPeriodExpires ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Subscription Expiry:
                                        </strong>{" "}
                                        {moment(
                                          filteredPrev?.subscriptionPeriodExpires
                                        ).format("lll")}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.plan ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Currency:
                                        </strong>{" "}
                                        {filteredPrev?.plan?.currency}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.plan ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Amount:
                                        </strong>{" "}
                                        {filteredPrev?.plan?.amount !== 0
                                          ? (
                                              filteredPrev?.plan?.amount / 100
                                            ).toFixed(2)
                                          : "-"}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.current_period_start ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Period Start:
                                        </strong>{" "}
                                        {moment
                                          .utc(
                                            filteredPrev?.current_period_start *
                                              1000
                                          )
                                          .format("lll")}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {filteredPrev?.current_period_end ? (
                                    <tr>
                                      <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                        <strong className="w-1/4">
                                          Period End:
                                        </strong>{" "}
                                        {moment
                                          .utc(
                                            filteredPrev?.current_period_end *
                                              1000
                                          )
                                          .format("lll")}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-center mt-4">
                        <h1 className="text-slate-600">
                          No Previous Subscriptions
                        </h1>
                      </div>
                    )}
                  </>
                )}
                {tabState === "entities" && (
                  <div className="w-full space-y-6">
                    {userSubscription?.entities?.list ? (
                      <>
                        <div className="w-full flex flex-row justify-around mt-2">
                          <p className="text-sm">
                            Active:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.entities?.Active}
                            </span>
                          </p>
                          <p className="text-sm">
                            Suspended:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.entities?.Suspended}
                            </span>
                          </p>
                          <p className="text-sm">
                            Total:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.entities?.Total}
                            </span>
                          </p>
                        </div>
                        <div className="w-full max-h-96 flex flex-col items-start overflow-y-auto">
                          {Object.values(userSubscription?.entities).length ===
                          0 ? (
                            ""
                          ) : (
                            <>
                              {Object.values(userSubscription?.entities?.list)
                                .length === 0
                                ? ""
                                : Object.values(
                                    userSubscription?.entities?.list
                                  ).map((e, i) => (
                                    <div key={i} className="w-full">
                                      <button
                                        className={`w-full flex items-start justify-between my-1 p-2 bg-slate-500 rounded-md`}
                                        onClick={() => {
                                          setShowEntityDetails(e?.entityId);
                                          setIsOpen((prev) =>
                                            e?.entityId === showEntityDetails
                                              ? setShowEntityDetails("")
                                              : true
                                          );
                                        }}
                                      >
                                        <p className="text-white">
                                          {e?.name}{" "}
                                          {e?.aprio === true ? "(Default)" : ""}
                                        </p>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5 text-white"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                                          />
                                        </svg>
                                      </button>
                                      {showEntityDetails === e?.entityId &&
                                        isOpen === true && (
                                          <table className="w-full">
                                            <thead></thead>
                                            <tbody>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Entity ID:
                                                  </strong>{" "}
                                                  {e.entityId}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Default Company:
                                                  </strong>{" "}
                                                  {e?.aprio === true
                                                    ? "Yes"
                                                    : "No"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Status.:
                                                  </strong>{" "}
                                                  {e?.status}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Description:
                                                  </strong>{" "}
                                                  {e?.description}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Scenario Count:
                                                  </strong>{" "}
                                                  {e?.scenarioCount}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Member Count:
                                                  </strong>{" "}
                                                  {e?.membersCount}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Currency:
                                                  </strong>{" "}
                                                  {e?.meta?.defaultCurrency}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Link Providers:
                                                  </strong>
                                                  {/* {e?.meta?.linkProviders?.map(e => (<p className='w-full font-normal'>{e}</p>))} */}
                                                  {e?.meta?.linkProviders?.[0]}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Date Created:
                                                  </strong>{" "}
                                                  {moment(
                                                    e?.meta?.dateCreated
                                                  ).format("lll")}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )}
                                    </div>
                                  ))}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {userSubscription?.entities?.Active &&
                    userSubscription?.ownedEntities ? (
                      <>
                        <div className="w-full flex flex-row justify-around mt-2">
                          <p className="text-sm">
                            Active:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.ownedEntities?.active}
                            </span>
                          </p>
                          <p className="text-sm">
                            Suspended:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.ownedEntities?.suspended}
                            </span>
                          </p>
                          <p className="text-sm">
                            Total:{" "}
                            <span className="text-xl font-semibold ml-2">
                              {userSubscription?.ownedEntities?.total}
                            </span>
                          </p>
                        </div>
                        <div className="w-full max-h-96 flex flex-col items-start overflow-y-auto pb-4">
                          {Object.values(userSubscription?.entities).length ===
                          0 ? (
                            ""
                          ) : (
                            <>
                              {Object.values(userSubscription?.entities?.Active)
                                .length === 0
                                ? ""
                                : Object.values(
                                    userSubscription?.entities?.Active
                                  ).map((e, i) => (
                                    <div key={i} className="w-full">
                                      <button
                                        className={`w-full flex items-start justify-between my-1 p-2 bg-slate-500 rounded-md`}
                                        onClick={() => {
                                          setShowEntityDetails(e?.entityId);
                                          setIsOpen((prev) =>
                                            e?.entityId === showEntityDetails
                                              ? setShowEntityDetails("")
                                              : true
                                          );
                                        }}
                                      >
                                        <p className="text-white">
                                          {e?.name}{" "}
                                          {e?.aprio === true ? "(Default)" : ""}
                                        </p>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5 text-white"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                                          />
                                        </svg>
                                      </button>
                                      {showEntityDetails === e?.entityId &&
                                        isOpen === true && (
                                          <table className="w-full">
                                            <thead></thead>
                                            <tbody>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Entity ID:
                                                  </strong>{" "}
                                                  {e.entityId}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Default Company:
                                                  </strong>{" "}
                                                  {e?.aprio === true
                                                    ? "Yes"
                                                    : "No"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Status.:
                                                  </strong>{" "}
                                                  {e?.status}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Description:
                                                  </strong>{" "}
                                                  {e?.description}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Scenario Count:
                                                  </strong>{" "}
                                                  {e?.scenarioCount}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Member Count:
                                                  </strong>{" "}
                                                  {e?.membersCount}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Currency:
                                                  </strong>{" "}
                                                  {e?.meta?.defaultCurrency}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Link Providers:
                                                  </strong>
                                                  {/* {e?.meta?.linkProviders?.map(e => (<p className='w-full font-normal'>{e}</p>))} */}
                                                  {e?.meta?.linkProviders?.[0]}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-1">
                                                  <strong className="w-1/4">
                                                    Date Created:
                                                  </strong>{" "}
                                                  {moment(
                                                    e?.meta?.dateCreated
                                                  ).format("lll")}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )}
                                    </div>
                                  ))}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {tabState === "userInformation" && (
                  <div className="w-full space-y-6 mb-4 max-h-96 overflow-y-auto">
                    <table className="w-full">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Name:</strong>{" "}
                            {userSubscription?.lastName},{" "}
                            {userSubscription?.firstName}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Login ID:</strong>{" "}
                            {userSubscription?.loginId}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Email:</strong>{" "}
                            {userSubscription?.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                            <strong className="w-1/4">Phone No.:</strong>{" "}
                            {userSubscription?.mobilePhone}
                          </td>
                        </tr>
                        {userSubscription?.company ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Company:</strong>{" "}
                              {userSubscription?.company}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?._accountStatus ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Status:</strong>{" "}
                              {userSubscription?._accountStatus}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.regPlatform ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Reg Platform:</strong>{" "}
                              {userSubscription?.regPlatform}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.mobileVerification ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">
                                Mobile Verification:
                              </strong>{" "}
                              {userSubscription?.mobileVerification}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.utm ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">UTM:</strong>{" "}
                              {userSubscription?.utm?.utm_source.toString()}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.dateCreated ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Date Created:</strong>{" "}
                              {moment(userSubscription?.dateCreated).format(
                                "lll"
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.dateActivated ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Date Activated:</strong>{" "}
                              {moment(userSubscription?.dateActivated).format(
                                "lll"
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.ssoRegTag ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">ssoRegTag:</strong>{" "}
                              <span className="font-semibold mr-4">
                                {Object.keys(userSubscription?.ssoRegTag)}:
                              </span>
                              <span>
                                {Object.values(userSubscription?.ssoRegTag)}
                              </span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {userSubscription?.lastLogin ? (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Last login:</strong>{" "}
                              {moment
                                .utc(
                                  parseInt(userSubscription?.lastLogin * 1000)
                                )
                                .format("lll")}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="w-full flex sm:flex-row flex-col border-b border-slate-300 p-2">
                              <strong className="w-1/4">Last login:</strong>{" "}
                              <p> - </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ViewUserSubscription;
