import React, { useContext } from 'react'
import UserListTable from '../../Components/Tables/UserListTable'
import Pulse from '../../Components/Toast/Pulse'
import HelpersContext from '../../helpers/GlobalHelpers'

function CustomerList() {

  const { isLoading } = useContext(HelpersContext)

  return (
    <div className='w-full h-auto mt-4 mr-10'>
        <div className={`w-auto h-full bg-slate-900 mr-4 rounded-md ${isLoading && `mr-8`}`}>
          <div className='w-full flex items-center  border-b border-slate-700 py-2'>
            <div className='w-3/4 px-6 py-2'>
              <h1 className='text-slate-100 text-xl'>Customer List</h1>
            </div>
          </div>
          <div className='w-full h-auto'>
            { !isLoading ? <UserListTable /> : <Pulse /> }
          </div>
        </div>
    </div>
  )
}

export default CustomerList