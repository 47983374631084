import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import Select from 'react-select'
import HelpersContext from '../../../../helpers/GlobalHelpers'
import PopUpContext from '../../../../helpers/PopUpContext'

function AddNewVersion() {

    const { appEnvi } = useContext(HelpersContext)
    const { setIsOpenAddVersion, setVNumber, setVEnvis, setIsOpenConfirmAddVersion, vNumber } = useContext(PopUpContext)

    const options = appEnvi.map(env => (
            {label: env.value, value: env.value}
        ))

    const handleSubmit = () => {
        if(vNumber === '') {
            toast.error('Please Provide version number')
        } else {
            setIsOpenAddVersion(false)
            setIsOpenConfirmAddVersion(true)
        }
    }

    console.log(options);

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 mx-6'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => {
                            setVNumber('')
                            setVEnvis('')
                            setIsOpenAddVersion(false)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center'>
                            <div className='flex items-center border-b border-slate-300 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                                </svg>
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold mb-2'>Add new enabled version</h1>
                                    <p>This will new enabled version</p>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    handleSubmit()}
                                    } action="" className='w-full'>
                                    <div className='flex flex-col mb-4'>
                                        <label htmlFor="env">Enter Version No.</label>
                                        <input type="text" name="version" onChange={(e) => setVNumber(e.target.value)} className='w-72 h-10 px-2 py-1 rounded-md border border-slate-700 mb-4' placeholder='Ex: 0.1.1' />
                                        <label htmlFor="">Environment</label>
                                        <Select options={options} onChange={(e) => setVEnvis(e)} multiValue isMulti/>
                                    </div>
                                    <div className='w-full flex items-center justify-center mt-6'>
                                        <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => {
                                            setVNumber('')
                                            setVEnvis('')
                                            setIsOpenAddVersion(false)
                                            }}>Cancel</button>
                                        <button type='submit' className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleSubmit()}>Confirm</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default AddNewVersion