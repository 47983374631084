import axios from "axios";
// import moment from 'moment/moment'
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InvalidCreds from "../../Components/Toast/InvalidCreds";
import HelpersContext from "../../helpers/GlobalHelpers";
import secureLocalStorage from "react-secure-storage";

function USLogin() {
  const [isValid, setIsValid] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUserInfo, setUserAccessToken, apiAuthURL } =
    useContext(HelpersContext);

  // const URL = "https://bos-api-vx.hivegroupinc.com/getauth/mAuth";
  const SERVER = "https://um-pre.moolamore.com";
  const navigate = useNavigate("");

  const keyString = "37693cfc748049e45d87b8c7d8b9aacd";
  function stringGen(len) {
    let text = "";

    for (let i = 0; i < len; i++)
      text += keyString.charAt(Math.floor(Math.random() * keyString.length));

    return text;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const key = stringGen(13);
    try {
      const response = await axios.post(`${apiAuthURL}/getauth/mauth`, {
        username: username,
        password: password,
        key: key,
        server: SERVER,
      });
      if (!response.data.data || !username || !password) {
        setIsValid(false);
      } else {
        secureLocalStorage.setItem("identifier", password);
        localStorage.setItem(
          "uri",
          "https://preprod-mapi.moolamore.com/supmgnt/main/"
        );
        setIsValid(true);
        setUserInfo(response.data.personalInfo);
        setUserAccessToken(response.data.personalInfo.accessToken);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            fullname: response.data.personalInfo.fullname,
            biometric_id: response.data.personalInfo.biometric_id,
            loginId: response.data.personalInfo.loginid,
            username: response.data.personalInfo.username,
            accessToken: response.data.personalInfo.accessToken,
          })
        );
        setUserInfo(response.data.personalInfo);
        localStorage.setItem("expiredAccess", false);
        localStorage.setItem("server", "pre");
        toast.success("Successful Login");
        navigate("/home");
      }
    } catch (error) {}
  };

  return (
    <div className="w-full md:h-screen min-h-screen bg-slate-800">
      <div className="w-full h-full flex md:flex-row flex-col">
        <div className="w-full flex flex-col">
          <div className="md:mb-0 mb-10">
            <img
              loading="lazy"
              src={`/images/moolamore-um-logo.png`}
              alt=""
              className="w-72"
            />
          </div>
          <div className="w-full h-full flex flex-col justify-center items-center">
            <h1 className="text-4xl text-cyan-300 mb-4">WELCOME</h1>
            {!isValid && <InvalidCreds />}
            <form onSubmit={(e) => handleSubmit(e)} action="" className="mb-4">
              <div className="flex flex-col text-slate-300 mb-8">
                <label htmlFor="" className="text-sm text-slate-400 mb-2">
                  Username
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setUsername(e.target.value)}
                  className="rounded-full h-10 w-72 py-1 px-4 text-black"
                />
              </div>
              <div className="flex flex-col text-slate-300 mb-8">
                <label htmlFor="" className="text-sm text-slate-400 mb-2">
                  Password
                </label>
                <input
                  type="password"
                  name=""
                  id=""
                  onChange={(e) => setPassword(e.target.value)}
                  className="rounded-full h-10 w-72 py-1 px-4 text-black"
                />
              </div>
              <div>
                <input
                  type="submit"
                  value="Sign In"
                  className="w-72 h-10 text-white rounded-full cursor-pointer hover:scale-110 transition ease-in-out delay-150 bg-gradient-to-r from-cyan-500 to-blue-500"
                />
              </div>
            </form>
            <div className="flex flex-col items-center">
              <h3 className="text-white text-lg">1.0.13</h3>
              <h3 className="text-cyan-500 text-sm">API-PRE-APAC: (APAC)</h3>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full flex justify-end">
          <img
            loading="lazy"
            src={`${SERVER}/assets/images/MobileBackground.png`}
            alt=""
            className="object-contain w-60 md:w-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default USLogin;
