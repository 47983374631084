import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../../../helpers/GlobalHelpers'
import PopUpContext from '../../../../helpers/PopUpContext'

function ConfirmUpdateVersion() {

    const { chosenVNumber, setIsOpenConfirmUpdateVersion, vEnvis, setConfirmPWRelogin } = useContext(PopUpContext)
    const { setAppSlider, URI, currentUserInfo } = useContext(HelpersContext)

    const fetchConf = async () => {
        const response = await axios.post(`${URI}getConf`, { accessToken: currentUserInfo.accessToken, category: 'appslider', type: 'versions' })
        if(!response.data._isError) {
            let data = Object.values(response.data.data)
            setAppSlider(data.map(u => u.data))
        } else {
            toast.error(response.data.reason)
        }
    }

    const handleUpdate = async () => {
        const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken, type: 'versions', category: 'appslider', value: chosenVNumber.value, delete: true })
        if(response.data._isError) {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setConfirmPWRelogin(true)
            } else {
                toast.error(`Oopps! ${response.data.reason}`)
                setIsOpenConfirmUpdateVersion(false)
            }
        } else {
            if(vEnvis.length === 0) {
                const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken, type: 'versions', category: 'appslider', value: chosenVNumber.value, append: true })
                if(response.data._isError) {
                    toast.error('Something went wrong!', response.data.reason)
                }
                fetchConf() 
                toast.success('Updated version')
            }
            if(vEnvis.length > 0) {
                let envis = vEnvis?.map(u => u?.value)
                const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken, type: 'versions', category: 'appslider', value: chosenVNumber.value, environment: envis, append: true  })  
                if(response.data._isError) {
                    toast.error('Something went wrong!', response.data.reason)
                }
                fetchConf()
                toast.success('Updated version') 
            }
        }
    }


    const handleConfirm = () => {
        handleUpdate()
        setIsOpenConfirmUpdateVersion(false)
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => setIsOpenConfirmUpdateVersion(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                                </svg>
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold'>Confirm Update slider version</h1>
                                    <p className='text-lg italic'>{chosenVNumber.value}</p>
                                </div>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenConfirmUpdateVersion(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleConfirm()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default ConfirmUpdateVersion