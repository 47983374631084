import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../../../helpers/GlobalHelpers'
import PopUpContext from '../../../../helpers/PopUpContext'

function ConfirmAddApiURL() {

    const { setIsOpenConfirmAddAPI, inputFields } = useContext(PopUpContext)
    const { setApiURL, URI, currentUserInfo } = useContext(HelpersContext)

    const fetchConf = async () => {
        const response = await axios.post(`${URI}getConf`, { accessToken: currentUserInfo.accessToken, category: 'apiURL', type: 'stage' })
        if(!response.data._isError) {
            let data = Object.values(response.data.data)
            setApiURL(data.map(u => u.data))
        }
    }

    const handleSubmit = async () => {
        let name = inputFields.map(u => u.name)
        let url = inputFields.map(u => u.url)
        let result = {}
        name.forEach((key,i) => result[key] = url[i])
        const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken, category: 'apiURL', type: 'stage', 
        value: result, append: true })
        if(!response.data._isError) {
            toast.success('Added new API URL')
            setIsOpenConfirmAddAPI(false)
            fetchConf()
        } else {
            toast.error(response.data.reason)
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => setIsOpenConfirmAddAPI(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                </svg>
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold'>Confirm</h1>
                                    <p className='mb-4'>Add new app environment (stage)</p>
                                </div>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenConfirmAddAPI(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleSubmit()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default ConfirmAddApiURL