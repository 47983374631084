import React, { useState } from 'react'
import EscalationsTable from '../../Components/Tables/EscalationsTable'
import ResolveTable from '../../Components/Tables/ResolveTable'

function EscalationListContainer() {

  const [ escTabState, setEscTabState ] = useState('open')

  return (
    <div className='w-full h-auto mt-4 mr-10'>
        <div className={`w-auto h-full bg-slate-900 mr-4 rounded-md `}>
          <div className='w-full flex items-center border-b border-slate-700  py-2'>
            <div className='w-3/4 px-6 py-2'>
              <h1 className='text-slate-100 text-xl'>Escalation List</h1>
            </div>
          </div>
          <div className='w-full h-auto'>
            <div className='mt-2 ml-6 flex items-center text-slate-100'>
               <div className={escTabState === 'open' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'}  onClick={() => setEscTabState('open')}>
                    <h2 className='text-base'>Open</h2>
               </div>
               <div className={escTabState === 'resolve' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setEscTabState('resolve')}>
                    <h2 className='text-base'>Resolve</h2>
               </div>
            </div>
            {escTabState === 'open' && <EscalationsTable /> }
            {escTabState === 'resolve' && <ResolveTable />}
          </div>
        </div>
    </div>
  )
}

export default EscalationListContainer