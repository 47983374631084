import React, { useState } from 'react'
import ApiURLTable from '../../Components/Tables/ApiURLTable'
import AppEnviTable from '../../Components/Tables/AppEnviTable'
import TokenExemptTable from '../../Components/Tables/TokenExemptTable'
import VSliderTable from '../../Components/Tables/VSliderTable'

function Settings() {

    const [ settingsTab, setSettingsTab ] = useState('slider')

    return (
        <div className='w-full h-auto mt-4 mr-10'>
            <div className="w-auto h-full bg-slate-900 mr-4 rounded-md">
                <div className='w-full flex items-center  border-b border-slate-700 py-2'>
                    <div className='w-full px-6 py-2 flex items-center justify-between'>
                        <h1 className='text-slate-100 text-xl'>Access List</h1>
                    </div>
                </div>
                <div className='w-full h-auto'>
                    <div className='w-auto overflow-x-auto mt-2 ml-6 flex items-center text-slate-100 border-b border-slate-400 mr-6'>
                        <div className={settingsTab === 'slider' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'}  onClick={() => setSettingsTab('slider')}>
                            <h2 className='text-base'>Version Slider</h2>
                        </div>
                        <div className={settingsTab === 'envi' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setSettingsTab('envi')}>
                            <h2 className='text-base'>App Environment</h2>
                        </div>
                        <div className={settingsTab === 'api' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'}  onClick={() => setSettingsTab('api')}>
                            <h2 className='text-base'>API URL</h2>
                        </div>
                        <div className={settingsTab === 'exempt' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setSettingsTab('exempt')}>
                            <h2 className='text-base'>Token Exemption</h2>
                        </div>
                    </div>
                    {settingsTab === 'slider' && <VSliderTable />}
                    {settingsTab === 'envi' && <AppEnviTable />}
                    {settingsTab === 'api' && <ApiURLTable />}
                    {settingsTab === 'exempt' && <TokenExemptTable />}
                </div>
            </div>
        </div>
    )
}

export default Settings