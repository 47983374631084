import React, { createContext, useState } from "react";


export const HelpersContext = createContext(null)

export const HelperContextProvider = ({children}) => {
    
    const [ userAccessToken, setUserAccessToken ] = useState('')
    const [ userInfo, setUserInfo ] = useState('')
    const [ userTabState, setUserTabState ] = useState('dashboard')
    const [ userList, setUserList ] = useState([])
    const [ initialUserState, setInitialUserState ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isLoadingDateSet, setIsLoadingDataSet ] = useState(false)
    const [ isLoadingUserList, setIsLoadingUserList ] = useState(false)
    const [ userPermission, setUserPermission ] = useState('')
    const [ escalationList, setEscalationList ] = useState([])
    const [ employeeList, setEmployeeList ] = useState([])
    const [ permissions, setPermissions ] = useState([])
    const [ empUsers, setEmpUsers ] = useState([]) 
    const [ empAdmin, setEmpAdmin ] = useState([])
    const [ groups, setGroups ] = useState([])
    const [ empGroup, setEmpGroups ] = useState([])
    const [ empGroupSuspended, setEmpGroupsSuspended ] = useState([])
    const [ empSuspended, setEmpSuspended ] = useState([]) 
    const [ currentUserGroup, setCurrentUserGroup ] = useState(null) 
    const [ userId, setUserId ] = useState(null) 
    const [ adminId, setAdminId ] = useState(null) 
    const [ suspendedId, setSuspendedId ] = useState(null) 
    const [ groupId, setGroupId ] = useState(null) 
    const [ suspendedGroupId, setSuspendedGroupId ] = useState(null) 
    const [ confirmType, setConfirmType] = useState('') 
    const [ navOpen, setNavOpen ] = useState(true)
    const [ totalUsers, setTotalUsers ] = useState(0)
    const [ appSlider, setAppSlider ] = useState([]) 
    const [ appEnvi, setAppEnvi ] = useState([]) 
    const [ apiURL, setApiURL ] = useState([]) 
    const [ tokenExempt, setTokenExempt ] = useState([])
    const [ chosenEnvi, setChosenEnvi ] = useState('') 
    const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    const URI = localStorage.getItem('uri')
        const [ apiAuthURL, setApiAuthURL ] = useState('') 
        
    const value = {
        currentUserInfo,
        userAccessToken, setUserAccessToken,
        userInfo, setUserInfo,
        userTabState, setUserTabState,
        userList, setUserList,
        initialUserState, setInitialUserState,
        isLoading, setIsLoading,
        isLoadingDateSet, setIsLoadingDataSet,
        isLoadingUserList, setIsLoadingUserList,
        userPermission, setUserPermission,
        escalationList, setEscalationList,
        navOpen, setNavOpen,
        totalUsers, setTotalUsers,
        employeeList, setEmployeeList,
        permissions, setPermissions,
        empUsers, setEmpUsers,
        empAdmin, setEmpAdmin,
        empSuspended, setEmpSuspended,
        groups, setGroups,
        empGroup, setEmpGroups,
        currentUserGroup, setCurrentUserGroup,
        userId, setUserId,
        adminId, setAdminId,
        suspendedId, setSuspendedId,
        groupId, setGroupId,
        confirmType, setConfirmType,
        empGroupSuspended, setEmpGroupsSuspended,
        suspendedGroupId, setSuspendedGroupId,
        appSlider, setAppSlider,
        appEnvi, setAppEnvi,
        apiURL, setApiURL,
        tokenExempt, setTokenExempt,
        chosenEnvi, setChosenEnvi,
        URI,
        apiAuthURL, setApiAuthURL,
    }

    return (
        <HelpersContext.Provider value={value}>
            {children}
        </HelpersContext.Provider>
    )
}

export default HelpersContext