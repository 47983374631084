import React from 'react'

function Pulse() {
  return (
    <div className="items-center shadow rounded-md p-4 w-full  mx-auto">
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-700 rounded"></div>
            <div className="space-y-3">
                <h2 className='text-slate-800 text-4xl'>Loading Data</h2>
                <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                <div className="h-2 bg-slate-700 rounded"></div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Pulse