// import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from "react";
// import toast from 'react-hot-toast'
// import HelpersContext from '../helpers/GlobalHelpers'
import PopUpContext from "../helpers/PopUpContext";

function MiniMenu({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    setIsUMOpen,
    setChosenUser,
    setOpenTA,
    setIsViewSubscription,
    setConfirmDelete,
  } = useContext(PopUpContext);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="p-1 rounded-full hover:bg-slate-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </button>
      )}
      {isOpen && (
        <div className="flex flex-col relative" ref={ref}>
          <div className="flex w-full items-end justify-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer rounded-sm bg-slate-700 hover:bg-slate-800 text-red-500"
              onClick={() => setIsOpen(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="flex flex-col absolute z-50 -top-10 -left-20 bg-slate-800 p-2 rounded-md">
            <button
              className="bg-cyan-600 hover:bg-cyan-700 rounded-sm py-1 px-2 mb-1"
              onClick={() => {
                setIsViewSubscription(true);
                setChosenUser(data.original);
                setIsOpen(false);
              }}
            >
              View
            </button>
            <button
              className="bg-green-600 hover:bg-green-700 rounded-sm py-1 px-2 mb-1"
              onClick={() => {
                setChosenUser(data.original);
                setIsUMOpen(true);
                setIsOpen(false);
              }}
            >
              Escalate
            </button>
            <button
              className="bg-red-500 hover:bg-red-600 rounded-sm py-1 px-2 mb-1"
              onClick={() => {
                setChosenUser(data.original);
                setConfirmDelete(true);
                setIsOpen(false);
              }}
            >
              Delete
            </button>
            <button
              className="bg-yellow-500 hover:bg-yellow-600 rounded-sm py-1 px-2"
              onClick={() => {
                setChosenUser(data.original);
                setOpenTA(true);
                setIsOpen(false);
              }}
            >
              Test Account
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MiniMenu;
