import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import validator from 'validator'
import HelpersContext from '../../../../helpers/GlobalHelpers'
import PopUpContext from '../../../../helpers/PopUpContext'


function AddTokenExempt() {

    const { URI, currentUserInfo } = useContext(HelpersContext)
    const { setIsOpenAddTokenExempt, setConfirmPWRelogin } = useContext(PopUpContext)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')


    const validateEmail = (e) => {
        let em = e.target.value

        if(validator.isEmail(em)) {
            setEmail(em)
            setEmailError('')
        } else {
            setEmailError('Please enter a valid Email')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!emailError) {
            const response = await axios.post(`${URI}revokeTokenExempt`, { accessToken: currentUserInfo.accessToken, email: email })
            if(!response.data._isError) {
                toast.error(response.data.reason)
                setIsOpenAddTokenExempt(false)
            } else {
                if(response.data?.isAuthorized?.valid === false) {
                    toast.error(response.data.isAuthorized.message)
                    setIsOpenAddTokenExempt(false)
                    setConfirmPWRelogin(true)
                } else {
                    setIsOpenAddTokenExempt(false)
                    toast.error(response.data.reason)
                }
            }
        }
    }

    return (
            <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
                <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                        <div className='w-full flex items-center justify-center flex-col'>
                            <div className='w-full flex items-end justify-end relative' onClick={() => setIsOpenAddTokenExempt(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className='w-full flex flex-col items-center'>
                                <div className='flex items-center border-b border-slate-300'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                    </svg>
                                    <div className='w-full flex flex-col items-start'>
                                        <h1 className='text-2xl font-bold mb-2'>Add new revoke token exemption</h1>
                                        <p>This will add a new token exemption.</p>
                                    </div>
                                </div>
                                <div className='flex flex-col mb-6'>
                                    <form onSubmit={(e) => handleSubmit(e)} action="" className='w-full'>
                                        <div className='flex flex-col mb-4'>
                                            <label htmlFor="env">Email</label>
                                            <input type="email" name="env" className='w-72 h-10 px-2 py-1 rounded-md border border-slate-700' onChange={(e) => validateEmail(e)}  placeholder='Enter email' />
                                            <span className='text-xs text-red-500 mb-2'>{emailError}</span>
                                        </div>
                                        <div className='w-full flex items-center justify-center'>
                                            <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenAddTokenExempt(false)}>Cancel</button>
                                            <button type='submit' className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white'>Confirm</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
    )
}

export default AddTokenExempt