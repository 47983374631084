import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import ModalContext from '../../helpers/ModalContext'
import SourceDoughnutModal from '../Charts/SourceDoughnutModal'
import SubsDoughnutModal from '../Charts/SubsDoughnutModal'
import SubsBarModal from '../Charts/SubsBarModal'
import ExpBarModal from '../Charts/ExpBarModal'

function PopUpContainer() {

    const { setIsModalOpen, userTypeData, sourceTypeData, chartType, subsTypeData, expData } = useContext(ModalContext)

    return (
        <div className='min-w-screen min-h-screen z-50 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex pb-10 items-center md:w-9/12 w-11/12 min-h-4/6 bg-slate-800 rounded-md text-slate-900 p-4'>
                        <div className='w-full flex items-center justify-center flex-col'>
                            <div className='w-full flex items-end justify-end' onClick={() => setIsModalOpen(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            {chartType === 1 && <>
                                <h1 className='text-slate-200 text-3xl mb-4'>USER TYPE</h1>
                                <SubsDoughnutModal userTypeData={userTypeData} />
                            </>}
                            {chartType === 2 && <>
                                <h1 className='text-slate-200 text-3xl mb-4'>SOURCE</h1>
                                <SourceDoughnutModal sourceTypeData={sourceTypeData} />
                            </>}
                            {chartType === 3 && <>
                                <h1 className='text-slate-200 text-3xl mb-4'>SUBSCRIPTIONS</h1>
                                <SubsBarModal subsTypeData={subsTypeData} />
                            </>}
                            {chartType === 4 && <>
                                <h1 className='text-slate-200 text-3xl mb-4'>EXPIRY</h1>
                                <ExpBarModal expData={expData} />
                            </>}
                        </div>
                    </motion.div>
            </div>
        </div>
    )
}

export default PopUpContainer