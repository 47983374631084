import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../../helpers/GlobalHelpers'
import PopUpContext from '../../../helpers/PopUpContext'

function UpdateEnvi() {

    const { setNewEnvi, setIsOpenUpdateEnvi, newEnvi, setIsOpenConfirmUpdateEnvi } = useContext(PopUpContext)
    const { chosenEnvi } = useContext(HelpersContext)

    const handleUpdateEnvi = async (e) => {
        e.preventDefault()
        if(newEnvi === chosenEnvi.value) {
            toast.error('Current entry already exist')
        } 
        else if (newEnvi === '') {
            toast.error('Field must not be empty')
        }     
        else {
            setIsOpenConfirmUpdateEnvi(true)
            setIsOpenUpdateEnvi(false)
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 mx-6'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => setIsOpenUpdateEnvi(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-2 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                                </svg>
                                
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold mb-2'>Update App Env</h1>
                                    <p>This will update an app Environment.</p>
                                    <p>{chosenEnvi.value}</p>
                                </div>
                            </div>
                            <div className='flex flex-col mb-6'>
                                <form action="" onSubmit={(e) => handleUpdateEnvi(e)} className='w-full'>
                                    <div className='flex flex-col mb-4'>
                                        <label htmlFor="env">Environment</label>
                                        <input type="text" name="env" onChange={(e) => setNewEnvi(e.target.value)} className='w-72 h-10 px-2 py-1 rounded-md border border-slate-700' placeholder={chosenEnvi.value} />
                                    </div>
                                    <div className='w-full flex items-center justify-center'>
                                        <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenUpdateEnvi(false)}>Cancel</button>
                                        <button type='submit' className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white'>Confirm</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default UpdateEnvi