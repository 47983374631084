import axios from "axios";
import { motion } from "framer-motion";
// import moment from 'moment/moment'
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import HelpersContext from "../../helpers/GlobalHelpers";
import PopUpContext from "../../helpers/PopUpContext";
import secureLocalStorage from "react-secure-storage";

function ReLoginPassword() {
  const { setConfirmPWRelogin } = useContext(PopUpContext);
  const { setUserList, setUserAccessToken, setUserTabState, apiAuthURL } =
    useContext(HelpersContext);
  const [password, setPassword] = useState("");

  const server = `https://um-stage.moolahmore.com`;
  const server2 = `https://um-pre.moolahmore.com`;
  const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serverTag = localStorage.getItem("server");

  const keyString = "37693cfc748049e45d87b8c7d8b9aacd";
  function stringGen(len) {
    let text = "";

    for (let i = 0; i < len; i++)
      text += keyString.charAt(Math.floor(Math.random() * keyString.length));

    return text;
  }

  let navigate = useNavigate("/");

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    const key = stringGen(13);

    try {
      if (apiAuthURL !== "") {
        const response = await axios.post(`${apiAuthURL}/getauth/mAuth`, {
          username: currentUserInfo.username,
          password: password,
          key: key,
          server: serverTag === "stage" ? server : server2,
        });

        if (!response.data.data) {
          toast.error("Incorrect Password!");
        } else {
          secureLocalStorage.setItem("identifier", password);
          localStorage.setItem("expiredAccess", false);
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              fullname: response.data.personalInfo.fullname,
              biometric_id: response.data.personalInfo.biometric_id,
              loginId: response.data.personalInfo.loginid,
              username: response.data.personalInfo.username,
              accessToken: response.data.personalInfo.accessToken,
            })
          );
          setUserAccessToken(response.data.personalInfo.accessToken);
          setConfirmPWRelogin(false);
          toast.success("Success!");
          navigate("/home");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error here
        console.error("404 Error - Resource not found:", error);
        // You might want to set an error state or show an error message to the user
      } else {
        // Handle other errors
        console.error("Error during authentication request:", error);
        // You might want to set an error state or show a generic error message to the user
      }
    }
    // try {
    //   const response = await axios.post(`${apiAuthURL}/getauth/mAuth`, {
    //     username: currentUserInfo.username,
    //     password: password,
    //     key: key,
    //     server: serverTag === "stage" ? server : server2,
    //   });

    //   if (!response.data.data) {
    //     toast.error("Incorrect Password!");
    //   } else {
    //     secureLocalStorage.setItem("identifier", password);
    //     localStorage.setItem("expiredAccess", false);
    //     localStorage.setItem(
    //       "userInfo",
    //       JSON.stringify({
    //         fullname: response.data.personalInfo.fullname,
    //         biometric_id: response.data.personalInfo.biometric_id,
    //         loginId: response.data.personalInfo.loginid,
    //         username: response.data.personalInfo.username,
    //         accessToken: response.data.personalInfo.accessToken,
    //       })
    //     );
    //     setUserAccessToken(response.data.personalInfo.accessToken);
    //     setConfirmPWRelogin(false);
    //     toast.success("Success!");
    //     navigate("/home");
    //   }
    // } catch (error) {
    //   if (error.response && error.response.status === 404) {
    //     // Handle 404 error here
    //     console.error("404 Error - Resource not found:", error.response.data);
    //     // You might want to set an error state or show an error message to the user
    //   } else {
    //     // Handle other errors
    //     console.error("Error during authentication request:", error);
    //     // You might want to set an error state or show a generic error message to the user
    //   }
    // }
  };

  const handleRelogin = async () => {
    await axios.get(
      server === "https://um-stage.moolahmore.com"
        ? `https://stage-mapi.moolamore.com/getauth/deAuth/`
        : `https://preprod-mapi.moolamore.com/getauth/deAuth/`
    );
    setUserList([]);
    navigate("/");
    setUserTabState("dashboard");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("expiredAccess");
    localStorage.removeItem("uri");
    localStorage.removeItem("server");
    localStorage.clear();
    window.location.reload();
    setConfirmPWRelogin(false);
  };

  return (
    <div className="min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40">
      <div className="w-screen h-auto flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ duration: 0.5 }}
          className="flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4"
        >
          <div className="w-full flex items-center justify-center flex-col px-4">
            <div className="w-full flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
              <div className="flex flex-col items-center mb-2">
                <h1 className="text-2xl text-bold">Invalid Access Token!</h1>
                <h1 className="text-sm text-bold mb-2">
                  Please confirm your password.
                </h1>
              </div>
              <form
                onSubmit={(e) => handleDeleteUser(e)}
                className="flex flex-col"
                action=""
              >
                <input
                  type="password"
                  className="rounded-lg mb-4 px-3 py-2 border border-slate-500"
                  name="confirmPassword"
                  id="pw"
                  autoComplete="off"
                  placeholder="*******"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="submit"
                  className="px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white cursor-pointer"
                  value="Confrim"
                />
              </form>
              <div className="flex flex-col items-center my-2">
                <div className="flex flex-row">
                  <p>or</p>
                </div>
                <div className="mt-2">
                  <Link to="/">
                    <button
                      className="text-sm text-blue-400"
                      onClick={handleRelogin}
                    >
                      Back to Login
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ReLoginPassword;
