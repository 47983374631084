import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import PopUpContext from '../../../../helpers/PopUpContext'

function DeleteApiURL() {

    const { currentAPI, setIsOpenDeleteAPI } = useContext(PopUpContext)

    console.log(currentAPI);

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative mb-4' onClick={() => setIsOpenDeleteAPI(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-6 text-red-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold'>Do you want to remove</h1>
                                    <p className='mb-4'>this api url?</p>
                                </div>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => setIsOpenDeleteAPI(false)}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' >Confirm</button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default DeleteApiURL