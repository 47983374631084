import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import TokenErrorNotification from "../../Components/TokenErrorNotification";
import axios from "axios";
import HelpersContext from "../../helpers/GlobalHelpers";

function LoginPage() {
  // const bgURL = ["https://um-stage.moolahmore.com/assets/images/MobileBackground.png","https://um-stage.moolahmore.com/assets/images/logo-csuite.png","https://um-stage.moolahmore.com/assets/images/Background.png"]
  const expiredAccess = localStorage.getItem("expiredAccess");
  const { setApiAuthURL } = useContext(HelpersContext);
  const setAccess = () => {
    localStorage.setItem("expiredAccess", false);
  };

  useEffect(() => {
    const getURL = async () => {
      const parseAuthURL = await axios.get(
        "https://stage-mapi.moolamore.com/utils/apiUrl/authUrl"
      );
      const data = parseAuthURL.data.data;
      const url = new URL(data.apiURL);
      const domain = url.origin;
      setApiAuthURL(domain);
    };
    getURL();
  }, [setApiAuthURL]);

  return (
    <div className="w-full h-screen bg-slate-800">
      {expiredAccess === "true" && <TokenErrorNotification />}
      <div className="w-full h-full flex md:flex-row flex-col">
        <div className="md:w-1/2 w-full md:h-full h-1/4 flex md:justify-end justify-center md:items-center items-end md:pr-5 md:pb-0 pb-10 md:mt-0 mt-8">
          <Link to="/loginAPAC-PROD" onClick={setAccess}>
            <button className="py-6 rounded-lg bg-slate-300 hover:text-white font-bold lg:w-96 md:w-56 w-48  hover:bg-gradient-to-r from-cyan-500 to-blue-500 transition ease-in-out scale hover:scale-110 md:ml-10 ml-0">
              APAC - PRE
            </button>
          </Link>
        </div>
        <div className="w-auto md:h-full h-auto flex md:justify-start justify-center md:items-center items-start md:pl-5">
          <Link to="/loginAPAC" onClick={setAccess}>
            <button className="py-6 rounded-lg bg-slate-300 hover:text-white font-bold lg:w-96 md:w-56 w-48  hover:bg-gradient-to-r from-cyan-500 to-blue-500 transition ease-in-out scale hover:scale-110">
              APAC - STAGE
            </button>
          </Link>
        </div>
        {/* <div className='md:w-auto w-full flex justify-end relative'>
                        <img src="https://um-stage.moolahmore.com/assets/images/MobileBackground.png" alt="" className='object-contain md:h-auto h-96 ' />
                    </div> */}
      </div>
    </div>
  );
}

export default LoginPage;
