import { motion } from 'framer-motion'
import moment from 'moment/moment'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../helpers/GlobalHelpers'
import PopUpContext from '../../helpers/PopUpContext'
import validator from 'validator'

function UserManagementModal() {

    const { setIsUMOpen, chosenUser, setConfirmEsc, setParams } = useContext(PopUpContext)
    const { escalationList, currentUserInfo } = useContext(HelpersContext)

    const [email, setEmail] = useState('')
    const [ticket, setTicket] = useState('')
    const [phone, setPhone] = useState('')
    const [altPhone, setAltPhone] = useState('N/A')
    const [issue, setIssue] = useState('')
    const [action, setAction] = useState('')
    const [resolve, setResolve] = useState('')
    const [validTicket, setValidTicket] = useState([])
    const [emailError, setEmailError] = useState('')

    const validateEmail = (e) => {
        let em = e.target.value

        if(validator.isEmail(em)) {
            setEmail(em)
            setEmailError('')
        } else {
            setEmailError('Please enter a valid Email')
        }

    }

    const handleEscalation = async (e) => {
        if(email && phone && ticket && issue && action && resolve) {
            e.preventDefault()
            setParams({
                accessToken: currentUserInfo.accessToken, 
                id: moment(new Date()).format('YYMMDDhmmss'),
                type: 'umEscalations',
                category: 'umEscalationIssues',
                idNo: currentUserInfo?.biometric_id,
                data: {
                    customer : {
                        loginId: '',
                        customerId: '',
                        customerName: `${chosenUser?.lastName} ${chosenUser?.firstName}`,
                        customerEmail: email,
                        customerTicketNumber: ticket,
                        customerPhone: phone,
                        customerAlternatePhone: altPhone,
                        customerIssue: issue
                    },
                    agent : {
                        idNo: currentUserInfo?.biometric_id,
                        agentName: currentUserInfo?.fullname
                    },
                    supervisor : {
                        supervisorId: '',
                        supervisorName: ''
                    },
                    actionTaken: action,
                    resolution: resolve,
                    date: moment(new Date()).format('MMM D, YYYY hh:mm a'),
                    isResolve: 0,
                    isActive: 1
                }
            })
            if(validTicket.includes(ticket)) {
                toast.error('Ticket Number already taken')
            } else {
                setConfirmEsc(true)
            }

        } else {
            toast.error('Required fields must be accomplished')
        }
    }

    useEffect(() => {
        setValidTicket(escalationList.filter(f => f.customer).map(u => u.customer.customerTicketNumber))
    },[escalationList])

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
                <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='h-auto flex rounded-lg bg-slate-200 p-4 w-auto'>
                        <div className='w-full flex h-3/4 items-center justify-center flex-col overflow-y-auto overflow-x-hidden'>
                            <div className='w-full flex items-end justify-end relative mt-1 mr-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg" onClick={() => setIsUMOpen(false)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className='flex flex-col items-center border-b border-slate-300 pb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-red-500">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
                                </svg>
                                <p className='text-2xl'><strong>Escalate Issue</strong></p>
                                <p>This will add an escalation to</p>
                                <p><strong>{chosenUser?.lastName}, {chosenUser?.firstName}</strong></p>
                            </div>
                            <div className='w-full h-auto flex flex-col mt-6 md:px-4 px-0'>
                                <form onSubmit={(e) => handleEscalation(e)} className='flex flex-col w-full md:h-full h-96' action="">
                                    <div className='flex'>
                                        <div className='w-full flex md:flex-row flex-col'>
                                            <div className='mr-4'>
                                                <div className='mb-2'>
                                                    <label htmlFor="email">Customer Email</label>
                                                </div>
                                                    <div className='flex flex-col'>
                                                        <input type="email" name="email" id="" placeholder='youremail@email.com' className='w-72 border-slate-500 rounded-lg px-3 py-2 border' onChange={(e) => validateEmail(e)} required />
                                                    <span className='text-xs text-red-500 mb-2'>{emailError}</span>
                                                    </div>
                                                <div className='mb-2 border'>
                                                    <label htmlFor="ticket">Ticket</label>
                                                </div>
                                                    <input type="text" name="ticket" id="" placeholder='12345' className='w-72 border-slate-500 rounded-lg px-3 py-2 mb-2 border' onChange={(e) => setTicket(e.target.value)} required />
                                                <div className='mb-2 border'>
                                                    <label htmlFor="phone">Phone #</label>
                                                </div>
                                                    <input type="number" name="phone" id="" placeholder='###-#####-####' className='w-72 border-slate-500 rounded-lg px-3 py-2 mb-2 border' onChange={(e) => setPhone(e.target.value)} required />
                                                <div className='mb-2 border'>
                                                    <label htmlFor="atlPhone">Alternate Phone #</label>
                                                </div>
                                                    <input type="number" name="altPhone" placeholder='Optional' className='w-72 border-slate-500 rounded-lg px-3 py-2 mb-2 border' onChange={(e) => setAltPhone(e.target.value)} id="" />
                                            </div>
                                            <div className='w-full flex flex-col'>
                                                <div className="mb-2">
                                                    <label htmlFor="issue">Customer Issue</label>
                                                </div>
                                                    <textarea name="issue" id="" cols="30" rows="3" placeholder='Type your issue here...' className='mb-2 rounded-lg border border-slate-500 px-3 py-2 w-72' onChange={(e) => setIssue(e.target.value)} required></textarea>
                                                <div className="mb-2">
                                                    <label htmlFor="action">Action</label>
                                                </div>
                                                    <textarea name="action" id="" cols="30" rows="3" placeholder='Type your action here...' className='mb-2 rounded-lg border border-slate-500 px-3 py-2 w-72' onChange={(e) => setAction(e.target.value)} required></textarea>
                                                <div className="mb-2">
                                                    <label htmlFor="resolve">Resolve</label>
                                                </div>
                                                    <textarea name="resolve" id="" cols="30" rows="3" placeholder='Type your resolve here...' className='mb-2 rounded-lg border border-slate-500 px-3 py-2 w-72' onChange={(e) => setResolve(e.target.value)} required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full flex  mt-4 space-x-2 pb-2'>
                                        <input type="submit" value="Submit" className='w-full rounded-lg bg-blue-600 hover:bg-blue-700 px-3 py-2 text-white cursor-pointer' onClick={(e) => 
                                            {
                                            handleEscalation(e)
                                            }
                                            } />
                                        <div className='rounded-lg bg-slate-700 hover:bg-slate-800 px-3 py-2 text-white cursor-pointer' onClick={() => {
                                            setIsUMOpen(false)
                                            setConfirmEsc(false)
                                            }}>Cancel
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
    )
}

export default UserManagementModal