import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../helpers/GlobalHelpers'
import PopUpContext from '../../helpers/PopUpContext'

function ConfirmEscPopUp() {

    const { setConfirmEsc, chosenUser, params, setIsUMOpen, setConfirmPWRelogin } = useContext(PopUpContext)
    const { setEscalationList, URI } = useContext(HelpersContext)

    const fetchEscalation = async () => {
        const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
        const response = await axios.post(`${URI}/getEscl?`, {accessToken: currentUserInfo.accessToken, type: 'umEscalations', category: 'umEscalationIssues', idNo: currentUserInfo.biometric_id})
            if(!response._isError) {
                const firstDrill = Object.values(response.data?.data)
                const secondDrill = firstDrill.map(u => {return u?.data})
                const escalationSet = secondDrill.map(u => {return u?.data})
                setEscalationList(escalationSet)
            }else {
                toast.error(response.data.reason)
            }
    }

    const handleEscalate = async () => {
        const response = await axios.post(`${URI}/setEscl?`, params)
        if(!response.data._isError) {
            toast.success('Success!')
            fetchEscalation()
            setConfirmEsc(false)
            setIsUMOpen(false)
            setEscalationList(prev => [...prev, response.data.data])
        } else {
            if(response.data?.isAuthorized?.valid === false) {
                toast.error(response.data.isAuthorized.message)
                setConfirmEsc(false)
                setIsUMOpen(false)
                setConfirmPWRelogin(true)
            } else {
                toast.error(`Oopps! ${response.data.reason}`)
                setConfirmEsc(false)
                setIsUMOpen(false)
            }
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-50 fixed flex items-center justify-center bg-black bg-opacity-40'>
                <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 sm:w-1/2 w-3/4 mx-6'>
                        <div className='w-full flex items-center justify-center flex-col'>
                            <div className='w-full flex items-end justify-end relative'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg" onClick={() => setConfirmEsc(false)}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className='w-full flex flex-col items-center mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-blue-500">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                </svg>
                                <h1 className='text-2xl mb-2'>Add Escalation</h1>
                                <p>add escalation to customer</p>
                                <p><strong>{chosenUser?.lastName} {chosenUser?.firstName}</strong></p>
                                <div className='w-full flex items-center justify-evenly mt-6'>
                                    <button className='rounded-lg px-3 py-2 text-white bg-slate-700 hover:bg-slate-800' onClick={() => setConfirmEsc(false)}>Cancel</button>
                                    <button className='rounded-lg px-3 py-2 text-white bg-blue-600 hover:bg-blue-700' onClick={() => handleEscalate()}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

    )
}

export default ConfirmEscPopUp