import React from 'react'
import { Doughnut } from 'react-chartjs-2'

function SubsDoughnutModal({ userTypeData }) {

  const options = {
    color: 'white',
    plugins: {
        legend: {
          labels: {
            generateLabels: function(chart) {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                const {
                    labels: {
                    pointStyle
                    }
                } = chart.legend.options;

                // const max = data.datasets[0].data.reduce((a, b) => (a + b), 0);

                    return data.labels.map((label, i) => {
                    const meta = chart.getDatasetMeta(0);
                    const style = meta.controller.getStyle(i);

                    return {
                    text: `${label} (${data.datasets[0].data[i]})`,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                    hidden: !chart.getDataVisibility(i),

                    index: i
                    };
                });
                }
            return [];
            },
            font: {
                size: 16
            }
          },
      },
      tooltip: {
          callbacks: {
              label: function(context) {
                const max = context.dataset.data.reduce((a, b) => (a + b), 0);
                return `${context.label} (${Math.round(context.parsed * 100 / max)}%)`
        //         
              }
          }
      }
    },
  }

  return (
    <div className='xl:w-6/12 lg:w-8/12 md:w-10/12'>
        <Doughnut data={userTypeData} options={options}/>
    </div>
  )
}

export default SubsDoughnutModal