import axios from 'axios'
import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import HelpersContext from '../../helpers/GlobalHelpers'
import PopUpContext from '../../helpers/PopUpContext'
import Select from 'react-select'

function NewModal() {

    const { setOpenNewAccess, setConfirmPWRelogin } = useContext(PopUpContext)
    const { employeeList, groups, setPermissions, permissions, URI, currentUserInfo } = useContext(HelpersContext) 


    const [ userChoice, setUserChoice ] = useState(null)
    const [ groupChoice, setGroupChoice ] = useState(null)
    const [ chosenCat, setChosenCat ] = useState('Users')
    const [ availUsers, setAvailUsers ] = useState([])
    const [ userPermissions, setUserPermissions ] = useState([])
    const [ groupPermissions, setGroupPermissions ] = useState([])
    const [ availGroups, setAvailGroups ] = useState([])
    
    const catType = ['Users', 'Groups']

    const options = availUsers.map(env => (
        {label: env.value, value: env.emp_id}
    ))

    const optionsGroups = availGroups.map(env => (
        {label: env.group_name, value: env.group_id}
    ))

    console.log(options);

    const fetchPermission = async () => {
        const response = await axios.get(`${URI}getPermissions?accessToken=${currentUserInfo.accessToken}`)
            if (!response.data._isError) {
                setPermissions(response.data.permissions)
            }
    }

    
    const handleSubmitUser = async (e) => {
        e.preventDefault()
        let user = employeeList?.filter(u => u?.emp_id === userChoice.value).map(e => e?.value)[0]
        let userId = employeeList?.filter(u => u?.emp_id === userChoice.value).map(e => e?.emp_id)[0]
        console.log(user);
        console.log(userId);
        if(!user && !userId) {
            toast.error('Choose a User or a Group')
        } else {
            const response = await axios.post(`${URI}setPermission`, { accessToken: currentUserInfo.accessToken, type: 'permission', category: 'users', name: user, value: userId })
            if(!response.data._isError) {
                setOpenNewAccess(false)
                toast.success('Added New User Permission')
                fetchPermission()
            } else {
                if(response.data?.isAuthorized?.valid === false) {
                    toast.error(response.data.isAuthorized.message)
                    setOpenNewAccess(false)
                    setConfirmPWRelogin(true)
                } else {
                    toast.error(`Oopps! ${response.data.reason}`)
                    setOpenNewAccess(false)
                }
            }
        }
    }

    const handleSubmitGroup = async (e) => {
        e.preventDefault()
        let groupie = Object.values(groups)
        let group = groupie?.filter(u => u?.group_id === groupChoice.value).map(e => e?.group_name)[0]
        let groupId = groupie?.filter(u => u?.group_id === groupChoice.value).map(e => e?.group_id)[0]
        if(!group && !groupId) {
            toast.error('Choose a User or a Group')
        } else {
            const response = await axios.post(`${URI}setPermission`, { accessToken: currentUserInfo.accessToken, type: 'permission', category: 'groups', name: group, value: groupId })
            if(!response.data._isError) {
                setOpenNewAccess(false)
                toast.success('Added New Group Permission')
                fetchPermission()
            } else {
                toast.error(response.data.reason)
            }
        }

    }
    
    useEffect(() => {
        setGroupPermissions(prev => [...prev, permissions?.groups, permissions?.suspended_groups].flat())
    },[permissions?.groups, permissions?.suspended_groups])

    useEffect(() => {
        let groupie = Object.values(groups)
        setAvailGroups(groupie?.filter(u => !groupPermissions?.find(e => u?.group_id === e?.toString())))
    },[groupPermissions, groups])

    useEffect(() => {
        setUserPermissions(prev => [...prev, permissions?.users, permissions?.admins, permissions?.suspended].flat())
    },[permissions?.users, permissions?.admins, permissions?.suspended, setUserPermissions])

    useEffect(() => {
        setAvailUsers(employeeList?.filter(u => !userPermissions?.find(e => u?.emp_id === e?.toString())))
    },[employeeList, userPermissions])
    

    return (
            <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
                <div className='w-screen h-auto flex items-center justify-center'>
                    <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4'>
                        <div className='w-full flex items-center justify-center flex-col px-4'>
                            <div className='w-full flex items-end justify-end relative' onClick={() => setOpenNewAccess(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className='flex flex-col items-center mt-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-green-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                </svg>
                            </div>
                            <div className='w-full flex flex-col items-center justify-center mb-4'>
                                <h1 className='text-2xl'>Add New Access</h1>
                                <p>This will add new access to the Support Team</p>
                            </div>
                            <div className='w-full flex flex-col items-start justify-center'>
                                <form action="" onSubmit={(e) => {
                                    chosenCat === 'Users' ? handleSubmitUser(e) : handleSubmitGroup(e)
                                }} className='w-full'>
                                    <label htmlFor="category" className='mb-2'>Category</label>
                                    <select name="category" onChange={(e) => setChosenCat(e.target.value)} className='w-full flex h-10 rounded-md border border-slate-900 mb-4'>
                                        <option disabled={true} value={null}>----</option>
                                        {catType?.map((c, i) => (
                                            <option key={i} value={c}>{c}</option>
                                        ))}
                                    </select>
                                    {chosenCat === 'Users' && 
                                        <Select onChange={(e) => setUserChoice(e)} options={options} />
                                    }
                                    {chosenCat === 'Groups' && 
                                        <Select onChange={(e) => setGroupChoice(e)} options={optionsGroups} />
                                    }
                                    <div className='w-full mt-6 flex items-end justify-end'>

                                        <button type='submit' className='px-3 py-2 bg-blue-600 hover:bg-blue-700 text-slate-200 rounded-lg'>Submit</button>
                                    </div>
                                </form>
                                <div className='w-full flex items-end justify-end'>
                                     <button className='px-3 py-2 bg-slate-700 hover:bg-slate-800 text-slate-200 rounded-lg mt-2' onClick={() => setOpenNewAccess(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
    )
}

export default NewModal