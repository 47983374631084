import moment from "moment";
import React, { createContext, useState } from "react";

export const DashboardContext = createContext(null)

export const DashboardContextProvider = ({children}) => {

    const [dataSet, setDataSet] = useState([])
    const [utype, setUtype] = useState([])
    const [source, setSource] = useState([])
    const [chosenUtype, setChosenUtype] = useState('all')
    const [chosenSource, setChosenSource] = useState('all')
    const [chosenDateFrom, setChosenDateFrom] = useState('2022-01-01')
    const [chosenDateTo, setChosenDateTo] = useState(moment().format('YYYY-MM-DD'))

    const value = {
        dataSet, setDataSet,
        utype, setUtype,
        source, setSource,
        chosenUtype, setChosenUtype,
        chosenSource, setChosenSource,
        chosenDateFrom, setChosenDateFrom,
        chosenDateTo, setChosenDateTo
    }

    return (
        <DashboardContext.Provider value={value} >
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardContext