import React, { createContext, useState } from "react";


export const PopUpContext = createContext(null)

export const PopUpContextProvider = ({children}) => {

    const [ isPopUpOpen, setIsPopUpOpen ] = useState(false)
    const [ isUMOpen, setIsUMOpen ] = useState(false)
    const [ currentTicket, setCurrentTicket ] = useState(null)
    const [ chosenUser, setChosenUser ] = useState(null)
    const [ confirmEsc, setConfirmEsc ] = useState(null)
    const [ confirmPW, setConfirmPW ] = useState(null)
    const [ confirmDelete, setConfirmDelete ] = useState(null)
    const [ confirmTa, setConfirmTA ] = useState(null)
    const [ openTA, setOpenTA ] = useState(null)
    const [ openNewAccess, setOpenNewAccess ] = useState(null)
    const [ adminPopUp, setAdminPopUp ] = useState(null)
    const [ isOpenAddEnvi, setIsOpenAddEnvi ] = useState(false)
    const [ isOpenDelEnvi, setIsOpenDelEnvi ] = useState(false)
    const [ isOpenUpdateEnvi, setIsOpenUpdateEnvi ] = useState(false)
    const [ isOpenConfirmUpdateEnvi, setIsOpenConfirmUpdateEnvi ] = useState(false)
    const [ isOpenAddEnviConfirm, setIsOpenAddEnviConfirm ] = useState(false)
    const [ isOpenAddVersion, setIsOpenAddVersion ] = useState(false)
    const [ isOpenConfirmAddVersion, setIsOpenConfirmAddVersion ] = useState(false)
    const [ isOpenRemoveVersion, setIsOpenRemoveVersion ] = useState(false)
    const [ isOpenUpdateVersion, setIsOpenUpdateVersion ] = useState(false)
    const [ isOpenConfirmUpdateVersion, setIsOpenConfirmUpdateVersion ] = useState(false)
    const [ isOpenAddTokenExempt, setIsOpenAddTokenExempt ] = useState(false)
    const [ isOpenRemoveTokenExempt, setIsOpenRemoveTokenExempt ] = useState(false)
    const [ isOpenAddAPI, setIsOpenAddAPI ] = useState(false)
    const [ isOpenConfirmAddAPI, setIsOpenConfirmAddAPI ] = useState(false)
    const [ isOpenDeleteAPI, setIsOpenDeleteAPI ] = useState(false)
    const [ isViewSubscription, setIsViewSubscription ] = useState(false)
    const [ newEnvi, setNewEnvi ] = useState('')
    const [ vNumber, setVNumber ] = useState('')
    const [ vEnvis, setVEnvis ] = useState([])
    const [ currentAPI, setCurrentAPI ] = useState('') 
    const [ chosenVNumber, setChosenVNumber ] = useState('')
    const [ chosenTokenExempt, setChosenTokenExempt ] = useState('')
    const [ params, setParams ] = useState({})
    const [ confirmPWRelogin, setConfirmPWRelogin ] = useState(null)
    const [ inputFields, setInputFields ] = useState([
        { name: '', url: '' }
    ])

    const value = {
        isPopUpOpen, setIsPopUpOpen,
        currentTicket, setCurrentTicket,
        isUMOpen, setIsUMOpen,
        chosenUser, setChosenUser,
        confirmEsc, setConfirmEsc,
        confirmPW, setConfirmPW,
        confirmDelete, setConfirmDelete,
        confirmTa, setConfirmTA,
        params, setParams,
        openTA, setOpenTA,
        openNewAccess, setOpenNewAccess,
        adminPopUp, setAdminPopUp,
        isOpenAddEnvi, setIsOpenAddEnvi ,
        isOpenAddEnviConfirm, setIsOpenAddEnviConfirm,
        newEnvi, setNewEnvi,
        isOpenDelEnvi, setIsOpenDelEnvi,
        isOpenUpdateEnvi, setIsOpenUpdateEnvi,
        isOpenConfirmUpdateEnvi, setIsOpenConfirmUpdateEnvi,
        isOpenAddVersion, setIsOpenAddVersion,
        isOpenConfirmAddVersion, setIsOpenConfirmAddVersion,
        vNumber, setVNumber,
        vEnvis, setVEnvis,
        isOpenRemoveVersion, setIsOpenRemoveVersion,
        isOpenUpdateVersion, setIsOpenUpdateVersion,
        chosenVNumber, setChosenVNumber,
        isOpenConfirmUpdateVersion, setIsOpenConfirmUpdateVersion,
        isOpenAddTokenExempt, setIsOpenAddTokenExempt,
        isOpenRemoveTokenExempt, setIsOpenRemoveTokenExempt,
        chosenTokenExempt, setChosenTokenExempt,
        isOpenAddAPI, setIsOpenAddAPI,
        isOpenConfirmAddAPI, setIsOpenConfirmAddAPI,
        isOpenDeleteAPI, setIsOpenDeleteAPI,
        inputFields, setInputFields,
        currentAPI, setCurrentAPI,
        isViewSubscription, setIsViewSubscription,
        confirmPWRelogin, setConfirmPWRelogin 
    }

    return (
        <PopUpContext.Provider value={value}>
            {children}
        </PopUpContext.Provider>
    )
}

export default PopUpContext