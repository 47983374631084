import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import PopUpContext from '../../helpers/PopUpContext'

function SetTestAccount() {

    const { setConfirmTA, chosenUser, setOpenTA } = useContext(PopUpContext)

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4'>
                    <div className='w-full flex items-center justify-center flex-col px-4'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => setOpenTA(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='flex flex-col items-center mt-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-20 h-20 mb-4 ${chosenUser?._isTestUser === true ? `text-red-500` : `text-orange-500`}`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <h1 className='text-xl'>You are about to {chosenUser?._isTestUser === true ?  <strong>Unset</strong> : <strong>Set</strong>} </h1> 
                            <p className='text-xl my-2'><strong>{chosenUser?.lastName}, {chosenUser?.firstName}</strong></p>
                            <h1 className='text-xl'>as a Test Account!</h1>
                        </div>
                        <div className='mt-6 flex'>
                            <button className='px-3 py-2 bg-slate-700 hover:bg-slate-800 text-slate-200 rounded-lg mr-2' onClick={() => setOpenTA(false)}>Cancel</button>
                            <button className='px-3 py-2 bg-blue-600 hover:bg-blue-700 text-slate-200 rounded-lg' onClick={() => {
                                setConfirmTA(true)
                                setOpenTA(false)
                            }}>Proceed</button>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default SetTestAccount