import { motion } from "framer-motion";
import React, { useContext } from "react";
import PopUpContext from "../../helpers/PopUpContext";

function ConfirmDelete() {
  const { setConfirmDelete, chosenUser, setConfirmPW } =
    useContext(PopUpContext);

  const confirmDelete = async () => {
    setConfirmDelete(false);
    setConfirmPW(true);

    // const response = await axios.get(
    //   `${URI}/deleteUserAccount?accessToken=${currentUserInfo.accessToken}&email=${chosenUser.email}`
    // );
    // if (!response.data._isError) {
    //   setConfirmDelete(false);
    //   setUserList((prev) =>
    //     prev.filter((user) => user.email !== chosenUser.email)
    //   );
    //   setInitialUserState((prev) =>
    //     prev.filter((user) => user.email !== chosenUser.email)
    //   );
    //   toast.success("User Deleted");
    // } else {
    //   if (response.data?.isAuthorized?.valid === false) {
    //     toast.error(response.data.isAuthorized.message);
    //     setConfirmDelete(false);
    //     setConfirmPWRelogin(true);
    //   } else {
    //     toast.error(`Oopps! ${response.data.reason}`);
    //     setConfirmDelete(false);
    //   }
    // }
  };

  return (
    <div className="min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40">
      <div className="w-screen h-auto flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ duration: 0.5 }}
          className="flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4 mx-6"
        >
          <div className="w-full flex items-center justify-center flex-col">
            <div className="w-full flex items-end justify-end relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg"
                onClick={() => setConfirmDelete(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="w-full flex flex-col items-center mb-6">
              <div className="flex items-center border-b border-slate-300 mb-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-24 h-24 text-red-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>
                <div className="w-full flex flex-col items-start pl-6">
                  <h1 className="text-2xl font-bold mb-2">Confirm Deletion</h1>
                  <p>Do you want to delete this account?</p>
                  <p className="my-4 text-sm">
                    <span className="underline decoration-red-500">
                      Warning!
                    </span>{" "}
                    You can not undo this action. Once deleted - account can not
                    be recovered along with all it's data.
                  </p>
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <p>
                  <strong>Name: </strong>
                  {chosenUser?.lastName} {chosenUser?.firstName}
                </p>
                <p>
                  <strong>Email: </strong>
                  {chosenUser?.email}
                </p>
              </div>
              <div className="w-full flex items-center justify-center">
                <button
                  className="px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white"
                  onClick={() => confirmDelete()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ConfirmDelete;
