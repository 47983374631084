import React, { useMemo, useRef, useContext } from 'react'
import { useGlobalFilter, useSortBy, useTable, usePagination, useColumnOrder } from 'react-table'
import { GlobalFilter } from '../../helpers/SearchFilter'
import { useDownloadExcel } from 'react-export-table-to-excel'
import { motion } from 'framer-motion'
import moment from 'moment'
import DashboardContext from '../../helpers/DashboardContext'

function DBTable({dataSet}) {

    const { chosenUtype } = useContext(DashboardContext)

    const tableRef = useRef(null)

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: chosenUtype + ' ' + moment(new Date()).format('DD-MMM-YYYY'),
        sheet: 'Users'
    })

    const data = useMemo(() => [...dataSet], [dataSet])
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            sortType: (row1, row2, columnName) => {
                const rowOneColumn = row1.values[columnName];
                const rowTwoColumn = row2.values[columnName];
                if (Number.isNaN(rowOneColumn) && Number.isNaN(rowTwoColumn)) {
                    return 0
                }
                if (isNaN(rowOneColumn) && isNaN(rowTwoColumn)) {
                    return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
                }
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: (row1, row2, columnName) => {
                const rowOneColumn = row1.values[columnName];
                const rowTwoColumn = row2.values[columnName];
                if (Number.isNaN(rowOneColumn) && Number.isNaN(rowTwoColumn)) {
                    return 0
                }
                if (isNaN(rowOneColumn) && isNaN(rowTwoColumn)) {
                    return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
                }
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Created',
            accessor: 'accountCreated',
            sortType: (a, b) => {
                  return new Date(b.values.accountCreated) - new Date(a.values.accountCreated);
                }
        },
        {
            Header: 'Activated',
            accessor: 'accountActivated',
            sortType: (a, b) => {
                  return new Date(b.values.accountCreated) - new Date(a.values.accountCreated);
                }
        },
        {
            Header: 'Type',
            accessor: 'userType'
        },
        {
            Header: 'Source',
            accessor: 'source',
            sortType: (rowA, rowB, columnId, desc) => {
                if (!rowA.values[columnId] && !rowB.values[columnId]) {
                    return 0;
                }
                if (!rowA.values[columnId]) {
                    return desc ? -1 : 1;
                }
                if (!rowB.values[columnId]) {
                    return desc ? 1 : -1;
                }
                return rowA.values[columnId].localeCompare(rowB.values[columnId]);
            },
        },
        {
            Header: 'Product ID',
            accessor: 'productId',
            sortType: (rowA, rowB, columnId, desc) => {
                if (!rowA.values[columnId] && !rowB.values[columnId]) {
                    return 0;
                }
                if (!rowA.values[columnId]) {
                    return desc ? -1 : 1;
                }
                if (!rowB.values[columnId]) {
                    return desc ? 1 : -1;
                }
                return rowA.values[columnId].localeCompare(rowB.values[columnId]);
            },
            
        },
        {
            Header: 'Max Company',
            accessor: (props) => {
                return props.maxEntities === -1 ? 'Unlimited' : props.maxEntities
            }
        },
        {
            Header: 'Coverage',
            accessor: 'coverage'
        },
        {
            Header: 'Price',
            accessor: (props) => {
                return props.price !== 0 ? (props.price / 100).toFixed(2) : '-'
            }
        },
        {
            Header: 'Subs. Started',
            accessor: 'subscriptionStarted',
            sortType: (a, b) => {
                  return new Date(b.values.subscriptionStarted) - new Date(a.values.subscriptionStarted);
                }
        },
        {
            Header: 'Subs. Expiry',
            accessor: 'subscriptionExpiry',
            sortType: (a, b) => {
                  return new Date(b.values.subscriptionExpiry) - new Date(a.values.subscriptionExpiry);
                }
        },
    ], [])

    const tableInstance = useTable({columns, data}, useGlobalFilter ,useSortBy , usePagination, useColumnOrder)

    const { 
            getTableProps, 
            getTableBodyProps, 
            headerGroups, 
            prepareRow, 
            state, 
            preGlobalFilteredRows, 
            setGlobalFilter, 
            page,
            nextPage,
            previousPage,
            canNextPage,
            canPreviousPage,
            pageOptions,
            gotoPage,
            pageCount,
            setPageSize,
        } = tableInstance

        const {pageIndex, pageSize} = state

    const keyString = '37693cfc748049e45d87b8c7d8b9aacd'
    function stringGen(len) {
        let text = "";
        
        for (let i = 0; i < len; i++)
            text += keyString.charAt(Math.floor(Math.random() * keyString.length));
        
        return text;
    }

    return (
        <>
            <div className='w-full flex flex-col'>
                {!dataSet || dataSet?.length === 0 ?
                <>
                <div className='w-full flex items-center justify-center my-4'>
                    <h1 className='text-white'>Nothing to show</h1>
                </div>
                </>
                :
                <div className='w-full flex flex-col'>
                    <div className='w-full flex flex-col '>
                        <div className='w-auto pt-2 flex sm:flex-row-reverse flex-col justify-between items-center mb-2'>
                            <div className='flex flex-col justify-end items-end mx-2'>
                                <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
                            </div>
                            <div className='flex items-center'>
                                <h3 className='text-slate-100'>Show</h3>
                                <select className='text-black rounded-md mx-2 py-1' name="" id="" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10,25,50,100, `${dataSet.filter(u => u.name).length}`].map(pageSize => (
                                        <option className='text-black' key={stringGen(13)} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))
                                }
                                </select>
                            </div>
                        </div>
                        <div className='flex'>
                                <button className='mb-2 text-xs bg-cyan-600 hover:bg-cyan-700 text-slate-200 px-2 py-1 rounded-md' onClick={onDownload}> Export to excel </button>
                        </div>
                        <div className='w-full max-h-96 overflow-auto mb-4 scrollbar-thumb-cyan-700 scrollbar-thin scrollbar-track-gray-100  scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded-md'>
                            <table ref={tableRef} className='w-full bg-slate-900 rounded-t-md' {...getTableProps()}>
                            <thead className='sticky top-0'>
                                {headerGroups.map((headerGroup,i) => (
                                    <tr key={i} className='text-xs md:text-sm lg:text-md text-slate-300 text-left' {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((col, i) => (
                                        <th key={i} className='text-left px-4 py-2 bg-slate-700 hover:bg-slate-600' {...col.getHeaderProps(col.getSortByToggleProps())}> { col.render("Header") }
                                        {col.isSorted ? (col.isSortedDesc ? <><span className='ml-2 text-xs text-cyan-500'>&#x25BC;</span></> : 
                                        <><span className='ml-2 text-xs text-cyan-500'>&#9650;</span></>) : ""}
                                        </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row,i) => {
                                    prepareRow(row)
                                    return  <tr  key={i} className='text-xs md:text-sm lg:text-md text-slate-300 border-b border-slate-700' {...row.getRowProps()}>
                                                { row.cells.map((cell,i) => (
                                                    <motion.td animate={{ opacity: [0,1] }} transition={{ duration: 0.3, delay: i * 0.05 }} key={i} className='truncate p-4' {...cell.getCellProps()}> {cell.render("Cell")} </motion.td>
                                                ))}
                                            </tr>
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='flex sm:flex-row flex-col justify-between items-center text-slate-300'>
                        <div className='flex'>
                            <span>
                            Page {''} 
                            <strong className='text-2xl'><span className='text-3xl text-slate-100 underline'>{pageIndex + 1}</span> of {pageOptions.length}</strong>
                            </span>
                        </div>
                        <div className='flex md:items-center items-end md:flex-row flex-col'>
                            <span className='md:pr-4 pr-0 md:mb-0 mb-2'>
                                Go to page: {''}
                                <input type="number" defaultValue={pageIndex + 1} onChange={e => {
                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(pageNumber)
                                }} className='w-14 py-1 rounded-md text-slate-900 pl-2'/>
                            </span>
                            <div className='text-slate-800'>
                                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={`bg-slate-200 px-2 py-1 rounded-tl-md rounded-bl-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canPreviousPage ? `cursor-not-allowed` : ''}`}>{`<<`}</button>
                                <button onClick={() => previousPage()} disabled={!canPreviousPage} className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canPreviousPage ? `cursor-not-allowed` : ''}`}>prev</button>
                                <button onClick={() => nextPage()} disabled={!canNextPage} className={`px-2 py-1 bg-slate-200 cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canNextPage ? `cursor-not-allowed` : ''}`}>next</button>
                                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={`bg-slate-200 px-2 py-1 rounded-tr-md rounded-br-md cursor-pointer hover:bg-blue-600 hover:text-slate-200 ${!canNextPage ? `cursor-not-allowed` : ''}`}>{`>>`}</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </>
    )
}

export default DBTable