import React, { useContext, useEffect } from "react";
import NavigationBar from "../Components/NavigationBar";
import TopBar from "../Components/TopBar";
import UserManagement from "./User Management/UserManagement";
import Dashboard from "./Dashboard/Dashboard";
import HelpersContext from "../helpers/GlobalHelpers";
// import { useNavigate } from 'react-router-dom'
import axios from "axios";
import DashboardContext from "../helpers/DashboardContext";
import PopUpContainer from "../Components/SearchPopUp/PopUpContainer";
import ModalContext from "../helpers/ModalContext";
import { AnimatePresence, motion } from "framer-motion";
import PopUpContext from "../helpers/PopUpContext";
import ViewEscalationModal from "../Components/Modals/ViewEscalationModal";
import UserManagementModal from "../Components/Modals/UserManagementModal";
import ConfirmEscPopUp from "../Components/Modals/ConfirmEscPopUp";
import ConfirmPW from "../Components/Modals/ConfirmPW";
import ConfirmDelete from "../Components/Modals/ConfirmDelete";
import SetTestAccount from "../Components/Modals/SetTestAccount";
import ConfrimTA from "../Components/Modals/ConfrimTA";
import AdminContainer from "./Admin/AdminContainer";
import NewModal from "../Components/Modals/NewModal";
import Confrim from "../Components/Modals/Admin/Confrim";
import AddEnvi from "../Components/Modals/Admin/AddEnvi";
import ConfirmAddEnv from "../Components/Modals/Admin/ConfirmAddEnv";
import ConfirmDeleteEnv from "../Components/Modals/Admin/ConfirmDeleteEnv";
import UpdateEnvi from "../Components/Modals/Admin/UpdateEnvi";
import ConfirmUpdateEnv from "../Components/Modals/Admin/ConfirmUpdateEnv";
import AddNewVersion from "../Components/Modals/Admin/VersionSlider/AddNewVersion";
import ConfirmNewVersion from "../Components/Modals/Admin/VersionSlider/ConfirmNewVersion";
import RemoveVersion from "../Components/Modals/Admin/VersionSlider/RemoveVersion";
import UpdateVersion from "../Components/Modals/Admin/VersionSlider/UpdateVersion";
import ConfirmUpdateVersion from "../Components/Modals/Admin/VersionSlider/ConfirmUpdateVersion";
import AddTokenExempt from "../Components/Modals/Admin/TokenExempt/AddTokenExempt";
import RemoveTokenExempt from "../Components/Modals/Admin/TokenExempt/RemoveTokenExempt";
import AddNewApiURL from "../Components/Modals/Admin/ApiURL/AddNewApiURL";
import ConfirmAddApiURL from "../Components/Modals/Admin/ApiURL/ConfirmAddApiURL";
import DeleteApiURL from "../Components/Modals/Admin/ApiURL/DeleteApiURL";
import ViewUserSubscription from "../Components/Modals/ViewUserSubscription";
import ReLoginPassword from "../Components/Modals/ReLoginPassword";
// import moment from 'moment'
import toast from "react-hot-toast";

function Home() {
  const { isModalOpen } = useContext(ModalContext);
  const {
    isPopUpOpen,
    isUMOpen,
    confirmEsc,
    confirmPW,
    confirmDelete,
    confirmTa,
    openTA,
    openNewAccess,
    adminPopUp,
    isOpenAddEnvi,
    isOpenAddEnviConfirm,
    isOpenDelEnvi,
    isOpenUpdateEnvi,
    isOpenConfirmUpdateEnvi,
    isOpenAddVersion,
    isOpenConfirmAddVersion,
    isOpenRemoveVersion,
    isOpenUpdateVersion,
    isOpenConfirmUpdateVersion,
    isOpenAddTokenExempt,
    isOpenRemoveTokenExempt,
    isOpenAddAPI,
    isOpenConfirmAddAPI,
    isOpenDeleteAPI,
    isViewSubscription,
    confirmPWRelogin,
    setConfirmPWRelogin,
  } = useContext(PopUpContext);
  const {
    URI,
    setUserList,
    setIsLoading,
    setEscalationList,
    navOpen,
    userTabState,
    currentUserInfo,
    setInitialUserState,
    setIsLoadingUserList,
    setTotalUsers,
    setEmployeeList,
    setPermissions,
    setGroups,
    setAppSlider,
    setAppEnvi,
    setApiURL,
    setTokenExempt,
    apiAuthURL,
    setApiAuthURL,
  } = useContext(HelpersContext);

  const {
    setDataSet,
    setUtype,
    setSource,
    chosenUtype,
    setChosenUtype,
    chosenSource,
    setChosenSource,
    chosenDateFrom,
    chosenDateTo,
  } = useContext(DashboardContext);

  // const navigate = useNavigate('')

  const handleUtype = (e) => {
    setChosenUtype(e.target.value);
    setChosenSource("all");
  };

  // setInterval(() => {
  //     let expiresIn = localStorage.getItem('expiresIn')
  //     let now = moment(Date.now()).format()

  //     if(moment(now).isAfter(expiresIn)) {
  //         setConfirmPWRelogin(true)
  //         clearInterval()
  //     }
  // }, 1000)

  // useEffect(() => {
  //     if(currentUserInfo === '' || currentUserInfo === null) {
  //         navigate('/')
  //     }
  // })

  useEffect(() => {
    const getURL = async () => {
      let server = localStorage.getItem("server");
      const parseAuthURL = await axios.get(
        server === "stage"
          ? "https://stage-mapi.moolamore.com/utils/apiUrl/authUrl"
          : "https://preprod-mapi.moolamore.com/utils/apiUrl/authUrl"
      );
      const data = parseAuthURL.data.data;
      const url = new URL(data.apiURL);
      const domain = url.origin;
      setApiAuthURL(domain);
    };
    getURL();
  }, [setApiAuthURL]);

  useEffect(() => {
    const fetchPermission = async () => {
      const response = await axios.get(
        `${URI}getPermissions?accessToken=${currentUserInfo?.accessToken}`
      );
      if (!response.data._isError) {
        setPermissions(response.data.permissions);
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
        }
      }
    };
    fetchPermission();
  }, [currentUserInfo?.accessToken, setPermissions, URI, setConfirmPWRelogin]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        if (apiAuthURL !== "") {
          const response = await axios.get(
            `${apiAuthURL}/getfeatures/getGroups`
          );

          if (response.status === 200) {
            // Data was successfully fetched
            setGroups(response.data.group_id);
          } else {
            // Handle other status codes, e.g., 404
            console.error(
              `Failed to fetch groups. Status code: ${response.status}`
            );

            if (response.status === 404) {
              // Handle 404 error here
              // For example, you can set an error state, show a message to the user, etc.
            } else if (response.data?.isAuthorized?.valid === false) {
              setConfirmPWRelogin(true);
            } else {
              // Handle other cases as needed
            }
          }
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error fetching groups:", error);
      }
    };
    fetchGroups();
  }, [
    currentUserInfo?.accessToken,
    setGroups,
    URI,
    setConfirmPWRelogin,
    apiAuthURL,
  ]);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (apiAuthURL !== "") {
        try {
          const response = await axios.get(
            `${apiAuthURL}/noauthsvc/getActiveUserList/json`
          );

          if (response.status === 200) {
            // Data was successfully fetched
            setEmployeeList(Object.values(response.data));
          } else {
            // Handle other status codes, e.g., 404
            console.error(
              `Failed to fetch employees. Status code: ${response.status}`
            );

            if (response.status === 404) {
              // Handle 404 error here
              // For example, you can set an error state, show a message to the user, etc.
            } else if (response.data?.isAuthorized?.valid === false) {
              setConfirmPWRelogin(true);
            } else {
              // Handle other cases as needed
            }
          }
        } catch (error) {
          // Handle network errors or other exceptions
          console.error("Error fetching employees:", error);

          // You might want to set an error state or show an error message to the user
        }
      }
    };
    fetchEmployees();
  }, [setEmployeeList, URI, setConfirmPWRelogin, apiAuthURL]);

  useEffect(() => {
    const fetchUtype = async () => {
      const response = await axios.post(`${URI}getSubsUtype?`, {
        accessToken: currentUserInfo?.accessToken,
      });
      if (response.data._isError) {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
          toast.error(`Oopps ${response.data.reason}`);
        }
      } else {
        setUtype(response.data.data);
      }
    };

    fetchUtype();
  }, [currentUserInfo?.accessToken, setUtype, URI, setConfirmPWRelogin]);

  useEffect(() => {
    const fetchSource = async () => {
      const response = await axios.post(`${URI}getSubsSource?`, {
        accessToken: currentUserInfo?.accessToken,
        utype: chosenUtype,
      });
      if (response.data._isError) {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
          toast.error(`Oopps ${response.data.reason}`);
        }
      } else {
        setSource(response.data.data);
      }
    };
    fetchSource();
  }, [
    currentUserInfo?.accessToken,
    setSource,
    chosenUtype,
    URI,
    setConfirmPWRelogin,
  ]);

  useEffect(() => {
    const fetchDataSet = async () => {
      const response = await axios.post(`${URI}getSubsDataset?`, {
        accessToken: currentUserInfo?.accessToken,
        utype: chosenUtype,
        source: chosenSource,
        date: `${chosenDateFrom},${chosenDateTo}`,
      });
      if (response.data._isError) {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
          toast.error(`Oopps ${response.data.reason}`);
        }
      } else {
        setDataSet(
          [...response.data.data].sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
      }
    };
    fetchDataSet();
  }, [
    chosenUtype,
    chosenSource,
    setDataSet,
    chosenDateFrom,
    chosenDateTo,
    currentUserInfo?.accessToken,
    URI,
    setConfirmPWRelogin,
  ]);

  useEffect(() => {
    const fetchUsers = async () => {
      setUserList([]);
      setInitialUserState([]);
      setIsLoadingUserList(true);
      setIsLoading(true);
      let d = 1;
      let skip = 0;

      while (d !== 0) {
        const response = await axios.post(`${URI}getUserlist?`, {
          accessToken: currentUserInfo?.accessToken,
          limit: "100",
          order: "desc",
          skip: skip,
        });
        if (response.data._isError === false) {
          skip += 100;
          setTotalUsers(response.data.usersCount.totalUsers);
          if (!response._isError) {
            if (skip === 100) {
              setIsLoading(false);
            }
            if (response.data.dataCount === 0) {
              d = response.data.dataCount;
              setIsLoadingUserList(false);
              break;
            } else {
              setUserList((prev) => [...prev, response.data.data].flat());
              setInitialUserState((prev) =>
                [...prev, response.data.data].flat()
              );
              if (response.data.dataCount < 100) {
                d = response.data.dataCount;
                setIsLoadingUserList(false);
                break;
              }
              setIsLoading(false);
            }
          } else {
            if (response.data?.isAuthorized?.valid === false) {
              setConfirmPWRelogin(true);
            } else {
              d = 0;

              toast.error(`Oopps ${response.data.reason}`);
              setIsLoading(false);
              break;
            }
          }
        } else {
          if (response.data?.isAuthorized?.valid === false) {
            setConfirmPWRelogin(true);
          } else {
            setIsLoading(false);
            toast.error(`Oopps ${response.data.reason}`);
          }
          break;
        }
      }
    };
    fetchUsers();
  }, [
    setIsLoading,
    setUserList,
    setInitialUserState,
    setIsLoadingUserList,
    setTotalUsers,
    URI,
    currentUserInfo?.accessToken,
    setConfirmPWRelogin,
  ]);

  useEffect(() => {
    const fetchEscalation = async () => {
      try {
        const response = await axios.post(`${URI}getEscl?`, {
          accessToken: currentUserInfo?.accessToken,
          type: "umEscalations",
          category: "umEscalationIssues",
          idNo: currentUserInfo?.biometric_id,
        });

        if (!response._isError) {
          // Check if response.data.data is defined before using Object.values
          if (response.data?.data) {
            const firstDrill = Object.values(response.data.data);
            const secondDrill = firstDrill.map((u) => {
              return u.data;
            });
            const escalationSet = secondDrill.map((u) => {
              return u.data;
            });
            setEscalationList(escalationSet);
          } else {
            // Handle the case when response.data.data is undefined or null
            console.error(
              "Response data is missing or invalid:",
              response.data
            );
          }
        } else {
          if (response.data?.isAuthorized?.valid === false) {
            setConfirmPWRelogin(true);
          } else {
            // Handle other cases as needed
          }
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error fetching escalation:", error);

        // You might want to set an error state or show an error message to the user
      }
    };

    fetchEscalation();
  }, [
    currentUserInfo?.accessToken,
    currentUserInfo?.biometric_id,
    setEscalationList,
    URI,
    setConfirmPWRelogin,
  ]);

  useEffect(() => {
    const fetchConf = async () => {
      const response = await axios.post(`${URI}getConf`, {
        accessToken: currentUserInfo?.accessToken,
        category: "appslider",
        type: "versions",
      });
      if (!response.data._isError) {
        let data = Object.values(response.data.data);
        setAppSlider(data.map((u) => u.data));
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
        }
      }
    };
    fetchConf();
  }, [currentUserInfo?.accessToken, setAppSlider, URI, setConfirmPWRelogin]);

  useEffect(() => {
    const fetchConf = async () => {
      const response = await axios.post(`${URI}getConf`, {
        accessToken: currentUserInfo?.accessToken,
        category: "app-config",
        type: "environment",
      });
      if (!response.data._isError) {
        let data = Object.values(response.data.data);
        setAppEnvi(data.map((u) => u.data));
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
        }
      }
    };
    fetchConf();
  }, [currentUserInfo?.accessToken, setAppEnvi, URI, setConfirmPWRelogin]);

  useEffect(() => {
    const fetchConf = async () => {
      const response = await axios.post(`${URI}getConf`, {
        accessToken: currentUserInfo?.accessToken,
        category: "apiURL",
        type: "stage",
      });
      if (!response.data._isError) {
        let data = Object.values(response.data.data);
        setApiURL(data.map((u) => u.data));
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
        }
      }
    };
    fetchConf();
  }, [currentUserInfo?.accessToken, setApiURL, URI, setConfirmPWRelogin]);

  useEffect(() => {
    const fetchConf = async () => {
      const response = await axios.post(`${URI}revokeTokenExempt`, {
        accessToken: currentUserInfo?.accessToken,
      });
      if (!response.data._isError) {
        let data = Object.values(response.data.data);
        setTokenExempt(data);
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          setConfirmPWRelogin(true);
        } else {
        }
      }
    };
    fetchConf();
  }, [currentUserInfo?.accessToken, setTokenExempt, URI, setConfirmPWRelogin]);

  return (
    <div className="w-full h-screen flex relative">
      <AnimatePresence>{isModalOpen && <PopUpContainer />}</AnimatePresence>
      <AnimatePresence>
        {isPopUpOpen && <ViewEscalationModal />}
      </AnimatePresence>
      <AnimatePresence>{isUMOpen && <UserManagementModal />}</AnimatePresence>
      <AnimatePresence>{confirmEsc && <ConfirmEscPopUp />}</AnimatePresence>
      <AnimatePresence>{confirmPW && <ConfirmPW />}</AnimatePresence>
      <AnimatePresence>{confirmDelete && <ConfirmDelete />}</AnimatePresence>
      <AnimatePresence>{openTA && <SetTestAccount />}</AnimatePresence>
      <AnimatePresence>{confirmTa && <ConfrimTA />}</AnimatePresence>
      <AnimatePresence>{openNewAccess && <NewModal />}</AnimatePresence>
      <AnimatePresence>{adminPopUp && <Confrim />}</AnimatePresence>
      <AnimatePresence>{isOpenAddEnvi && <AddEnvi />}</AnimatePresence>
      <AnimatePresence>
        {isOpenAddEnviConfirm && <ConfirmAddEnv />}
      </AnimatePresence>
      <AnimatePresence>{isOpenDelEnvi && <ConfirmDeleteEnv />}</AnimatePresence>
      <AnimatePresence>{isOpenUpdateEnvi && <UpdateEnvi />}</AnimatePresence>
      <AnimatePresence>
        {isOpenConfirmUpdateEnvi && <ConfirmUpdateEnv />}
      </AnimatePresence>
      <AnimatePresence>{isOpenAddVersion && <AddNewVersion />}</AnimatePresence>
      <AnimatePresence>
        {isOpenConfirmAddVersion && <ConfirmNewVersion />}
      </AnimatePresence>
      <AnimatePresence>
        {isOpenRemoveVersion && <RemoveVersion />}
      </AnimatePresence>
      <AnimatePresence>
        {isOpenUpdateVersion && <UpdateVersion />}
      </AnimatePresence>
      <AnimatePresence>
        {isOpenConfirmUpdateVersion && <ConfirmUpdateVersion />}
      </AnimatePresence>
      <AnimatePresence>
        {isOpenAddTokenExempt && <AddTokenExempt />}
      </AnimatePresence>
      <AnimatePresence>
        {isOpenRemoveTokenExempt && <RemoveTokenExempt />}
      </AnimatePresence>
      <AnimatePresence>{isOpenAddAPI && <AddNewApiURL />}</AnimatePresence>
      <AnimatePresence>
        {isOpenConfirmAddAPI && <ConfirmAddApiURL />}
      </AnimatePresence>
      <AnimatePresence>{isOpenDeleteAPI && <DeleteApiURL />}</AnimatePresence>
      <AnimatePresence>
        {isViewSubscription && <ViewUserSubscription />}
      </AnimatePresence>
      <AnimatePresence>
        {confirmPWRelogin && <ReLoginPassword />}
      </AnimatePresence>
      <AnimatePresence>
        {navOpen && (
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200 }}
            transition={{ duration: 0.5 }}
            className={`sm:w-72 w-auto h-screen flex ${!navOpen && `hidden`}`}
          >
            <NavigationBar />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="w-full overflow-hidden">
        <TopBar />
        <AnimatePresence>
          {userTabState === "dashboard" && (
            <Dashboard handleUtype={handleUtype} />
          )}
          {userTabState === "usermgt" && <UserManagement />}
          {userTabState === "admin" && <AdminContainer />}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Home;
