import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import Access from './Access'
import HelpersContext from '../../helpers/GlobalHelpers'
import Settings from './Settings'
import PopUpContext from '../../helpers/PopUpContext'
import axios from 'axios'
import toast from 'react-hot-toast'

function AdminContainer() {

    const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [ activeTab, setActiveTab ] = useState('access')
    const { employeeList, permissions, setEmpUsers, setEmpAdmin, setEmpSuspended, setEmpGroups, userId, setUserId, adminId, setAdminId, suspendedId, setSuspendedId, groupId, setGroupId, groups, suspendedGroupId, setSuspendedGroupId, setEmpGroupsSuspended, URI } = useContext(HelpersContext)
    const { setConfirmPWRelogin } = useContext(PopUpContext)

    useEffect(() => {
        const fetchPermission = async () => {
            const response = await axios.get(`${URI}getPermissions?accessToken=${currentUserInfo?.accessToken}`)
          if(!response.data._isError) {
          } else {
              if(response.data?.isAuthorized?.valid === false) {
                  toast.error(response.data?.isAuthorized?.message)
                  setConfirmPWRelogin(true)
              }
          }
        }
        fetchPermission()
    },[currentUserInfo.accessToken, setConfirmPWRelogin, URI])

    useEffect(() => {
        setUserId(permissions?.users)
        setEmpUsers(employeeList.filter(u => userId?.some(e => u?.emp_id === e.toString())))
    },[employeeList, permissions?.users, setUserId, userId, setEmpUsers])

    useEffect(() => {
        setAdminId(permissions?.admins)
        setEmpAdmin(employeeList.filter(u => adminId?.some(e => u?.emp_id === e.toString())))
    },[employeeList, permissions?.admins, setEmpAdmin, adminId, setAdminId])

    useEffect(() => {
        setSuspendedId(permissions?.suspended)
        setEmpSuspended(employeeList.filter(u => suspendedId?.some(e => u?.emp_id === e.toString())))
    },[employeeList, permissions?.suspended, setEmpSuspended, suspendedId, setSuspendedId])

    useEffect(() => {
        setGroupId(permissions?.groups)
        let groupie = Object.values(groups) 
        setEmpGroups(groupie.filter(u => groupId?.some(e => u?.group_id === e.toString())))
    },[groups, permissions?.groups, setEmpGroups, groupId, setGroupId])

    useEffect(() => {
        setSuspendedGroupId(permissions?.suspended_groups)
        let groupie = Object.values(groups)
        setEmpGroupsSuspended(groupie.filter(u => suspendedGroupId?.some(e => u?.group_id === e.toString())))
    },[groups, permissions?.suspended_groups, setEmpGroupsSuspended, setSuspendedGroupId, suspendedGroupId])

    // suspendedGroupId, setSuspendedGroupId

    return (
        <motion.div initial={{opacity:0, x:400}} animate={{opacity: 1, x:0}} exit={{opacity:0, x:-400}} transition={{duration: 1.2}} className='w-auto max-h-screen ml-4 overflow-y-auto'>
            <div className='w-auto h-auto flex flex-col mb-40'>
                <div className='pt-20'>
                    <h1 className='pl-2 font-bold text-4xl text-blue-500'>Permission</h1>
                </div>
                <div className='mt-16 flex items-center text-slate-100'>
                    <div className={activeTab === 'access' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setActiveTab('access')}>
                        <h2 className='text-lg'>Access</h2>
                    </div>
                    <div className={activeTab === 'settings' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setActiveTab('settings')}>
                        <h2 className='text-lg'>App Settings</h2>
                    </div>
                </div>
                { activeTab === 'access' && <Access /> }
                { activeTab === 'settings' && <Settings /> }
            </div>
        </motion.div>
    )
}

export default AdminContainer