import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import PopUpContext from "../../helpers/PopUpContext";
// import axios from 'axios'
import toast from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import HelpersContext from "../../helpers/GlobalHelpers";
import axios from "axios";
// import HelpersContext from '../../helpers/GlobalHelpers'

function ConfirmPW() {
  // const { setUserInfo } = useContext(HelpersContext)
  const [password, setPassword] = useState("");

  const { chosenUser, setConfirmPWRelogin, setConfirmPW } =
    useContext(PopUpContext);
  const { setUserList, setInitialUserState, URI, currentUserInfo } =
    useContext(HelpersContext);

  const confirmDelete = async () => {
    const response = await axios.get(
      `${URI}/deleteUserAccount?accessToken=${
        currentUserInfo.accessToken
      }&email=${encodeURIComponent(chosenUser.email)}`
    );
    if (!response.data._isError) {
      setConfirmPW(false);
      setUserList((prev) =>
        prev.filter((user) => user.email !== chosenUser.email)
      );
      setInitialUserState((prev) =>
        prev.filter((user) => user.email !== chosenUser.email)
      );
      toast.success("User Deleted");
    } else {
      if (response.data?.isAuthorized?.valid === false) {
        toast.error(response.data.isAuthorized.message);
        setConfirmPW(false);
        setConfirmPWRelogin(true);
      } else {
        toast.error(`Oopps! ${response.data.reason}`);
        setConfirmPW(false);
      }
    }
  };

  // const server = `https://um-stage.moolahmore.com`
  // const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))

  // const keyString = '37693cfc748049e45d87b8c7d8b9aacd'
  // function stringGen(len) {
  //     let text = "";

  //     for (let i = 0; i < len; i++)
  //         text += keyString.charAt(Math.floor(Math.random() * keyString.length));

  //     return text;
  // }

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    const identifier = secureLocalStorage.getItem("identifier");

    if (identifier === password) {
      setConfirmPW(false);
      confirmDelete();
    } else {
      toast.error("Incorrect Password!");
    }
    // const key = stringGen(13)
    // const response = await axios.post(`https://bos-api.hivegroupinc.com/getauth/mAuth`, {username: currentUserInfo.username, password: password, key: key, server: server })
    // if(!response.data.data) {
    //     toast.error('Incorrect Password!')
    // } else {
    //     localStorage.setItem('expiredAccess', false)
    //     localStorage.setItem('userInfo', JSON.stringify({fullname: response.data.personalInfo.fullname, biometric_id: response.data.personalInfo.biometric_id, username: response.data.personalInfo.username, accessToken: response.data.personalInfo.accessToken}))
    //     setConfirmDelete(true)
    //     setConfirmPW(false)
    // }
  };

  return (
    <div className="min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40">
      <div className="w-screen h-auto flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ duration: 0.5 }}
          className="flex rounded-lg bg-slate-200 p-4 md:w-1/2 xl:w-1/4"
        >
          <div className="w-full flex items-center justify-center flex-col px-4">
            <div className="w-full flex items-end justify-end relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg"
                onClick={() => setConfirmPW(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="w-full flex flex-col items-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
              <h1 className="text-2xl text-bold mb-2">Confirm Password</h1>
              <form
                onSubmit={(e) => handleDeleteUser(e)}
                className="flex flex-col"
                action=""
              >
                <input
                  type="password"
                  className="rounded-lg mb-4 px-3 py-2 border border-slate-500"
                  name="confirmPassword"
                  id="pw"
                  autoComplete="off"
                  placeholder="*******"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="submit"
                  className="px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white cursor-pointer"
                  value="Confrim"
                />
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ConfirmPW;
