import React, { useContext, useEffect } from "react";
import HelpersContext from "../helpers/GlobalHelpers";
import axios from "axios";

function TopBar() {
  const { setNavOpen, currentUserInfo, apiAuthURL, setApiAuthURL } =
    useContext(HelpersContext);

  useEffect(() => {
    const getURL = async () => {
      const parseAuthURL = await axios.get(
        "https://stage-mapi.moolamore.com/utils/apiUrl/authUrl"
      );
      const data = parseAuthURL.data.data;
      const url = new URL(data.apiURL);
      const domain = url.origin;
      setApiAuthURL(domain);
    };
    getURL();
  }, [setApiAuthURL]);

  return (
    <div className="w-full h-14 bg-black sticky top-0 z-30">
      <div className="w-full py-2 flex items-center justify-center">
        <div
          className="ml-4 mr-6 cursor-pointer transition-all ease-in delay-75"
          onClick={() => setNavOpen((prev) => !prev)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 text-white hover:scale-110 p-2 rounded-full bg-slate-900 bg-opacity-80 ease-linear"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-slate-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
        <div>
          <input
            type="search"
            name=""
            id=""
            className="ml-1 mr-2 w-52 md:flex hidden py-0"
          />
        </div>
        <div className="mx-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-slate-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
            />
          </svg>
        </div>
        <div className="mx-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-slate-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
            />
          </svg>
        </div>

        <div className="ml-auto pr-4 flex items-center mr-4">
          <div className="md:flex hidden text-slate-100 mr-4 text-lg">
            <h2>{currentUserInfo?.fullname}</h2>
          </div>
          <div className="h-10 w-10 rounded-full">
            <img
              loading="lazy"
              src={`${apiAuthURL}/docz/getPicture/${currentUserInfo?.biometric_id}`}
              alt=""
              className="w-10 h-10 rounded-full object-contain border-2 border-green-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
