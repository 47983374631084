import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'

function BarChartExpiry({expiryBarData}) {

    const options = {
        color: 'white',
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 12,
                    },
                    color: 'white'
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                      const max = Object.values(context.dataset.data).reduce((a, b) => (a + b), 0);
                      return `${Math.round(context.parsed.y * 100 / max)}%`
                    }
                }
            }
        }
    }


    return (
        <div className='w-full px-2'>
            <Bar data={expiryBarData} options={options} />
        </div>
    )
}

export default BarChartExpiry