import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import toast from 'react-hot-toast';
import HelpersContext from '../../../../helpers/GlobalHelpers';
import PopUpContext from '../../../../helpers/PopUpContext'

function ConfirmNewVersion() {

    const { vNumber, vEnvis, setIsOpenConfirmAddVersion, setVEnvis, setVNumber, setConfirmPWRelogin } = useContext(PopUpContext)
    const { setAppSlider, URI, currentUserInfo } = useContext(HelpersContext)

    const fetchConf = async () => {
        const response = await axios.post(`${URI}getConf`, { accessToken: currentUserInfo.accessToken , category: 'appslider', type: 'versions' })
        if(!response.data._isError) {
            let data = Object.values(response.data.data)
            setAppSlider(data.map(u => u.data))
        } else {
            toast.error(response.data.reason)
        }
    }

    const handleConfirm = async () => {
        if(vEnvis.length === 0) {
            const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken , type: 'versions', category: 'appslider', value: vNumber, append: true })
            if(!response.data._isError) {
                setIsOpenConfirmAddVersion(false)
                toast.success('Added new slider version')
                fetchConf()
            } else {
                if(response.data?.isAuthorized?.valid === false) {
                    toast.error(response.data.isAuthorized.message)
                    setIsOpenConfirmAddVersion(false)
                    setConfirmPWRelogin(true)
                } else {
                    toast.error(`Oopps! ${response.data.reason}`)
                    setIsOpenConfirmAddVersion(false)
                }
            }
        } else {
            let envis = vEnvis?.map(u => u?.value)
            const response = await axios.post(`${URI}setConf`, { accessToken: currentUserInfo.accessToken , type: 'versions', category: 'appslider', value: vNumber, environment: envis, append: true  })  
            if(!response.data._isError) {
                setIsOpenConfirmAddVersion(false)
                toast.success('Added new slider version')
                fetchConf()
            } else {
                if(response.data?.isAuthorized?.valid === false) {
                    toast.error(response.data.isAuthorized.message)
                    setIsOpenConfirmAddVersion(false)
                    setConfirmPWRelogin(true)
                } else {
                    toast.error(`Oopps! ${response.data.reason}`)
                    setIsOpenConfirmAddVersion(false)
                }
            }
        }
    }

    return (
        <div className='min-w-screen min-h-screen z-40 fixed flex items-center justify-center bg-black bg-opacity-40'>
            <div className='w-screen h-auto flex items-center justify-center'>
                <motion.div initial={{opacity:0, y:-200}} animate={{opacity: 1, y:0}} exit={{opacity:0, y:-200}} transition={{duration: .5}} className='flex rounded-lg bg-slate-200 p-4 w-auto px-10'>
                    <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full flex items-end justify-end relative' onClick={() => {
                            setVNumber('')
                            setVEnvis('')
                            setIsOpenConfirmAddVersion(false)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500 cursor-pointer bg-slate-900 hover:bg-slate-800 hover:scale-125 rounded-lg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className='w-full flex flex-col items-center mb-6'>
                            <div className='flex items-center border-b border-slate-300 mb-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 mr-6 text-blue-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                                </svg>
                                
                                <div className='w-full flex flex-col items-start'>
                                    <h1 className='text-2xl font-bold'>Confirm</h1>
                                    <p className='mb-4'>Add new app slider version</p>
                                    <p className='text-lg italic'>{vNumber}</p>
                                </div>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <button className='px-3 py-2 rounded-lg bg-slate-600 hover:bg-slate-700 text-white mr-4' onClick={() => {
                                    setVNumber('')
                                    setVEnvis('')
                                    setIsOpenConfirmAddVersion(false)
                                    }}>Cancel</button>
                                <button className='px-3 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white' onClick={() => handleConfirm()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default ConfirmNewVersion