import axios from "axios";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import HelpersContext from "../helpers/GlobalHelpers";

function NavigationBar() {
  const { setUserTabState } = useContext(HelpersContext);
  const server = localStorage.getItem("server");

  const [isActive, setIsActive] = useState(null);
  const navigate = useNavigate("");

  const logOut = async () => {
    try {
      await axios.get(
        server === "pre"
          ? "https://preprod-mapi.moolamore.com/getauth/deAuth"
          : "https://stage-mapi.moolamore.com/getauth/deAuth"
      );
      toast("Successful Log out", {
        icon: "👋",
      });
      navigate("/");
      setUserTabState("dashboard");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("expiredAccess");
      localStorage.removeItem("uri");
      localStorage.removeItem("server");
      localStorage.clear();
      window.location.reload();
    } catch (error) {}
  };

  return (
    <div className="w-full h-full bg-slate-900 bg-opacity-30">
      <div className="h-full flex flex-col pt-4 bg-opacity-30">
        <div className="sm:w-auto w-20 mb-24 sm:mt-0 mt-10">
          <img
            loading="lazy"
            src="/images/moolamore-um-logo.png"
            alt=""
            className="px-2  mr-6 sm:-rotate-0 -rotate-90 "
          />
        </div>
        <div className="h-full flex flex-col justify-between">
          <div className="h-auto w-full flex flex-col sm:pl-6">
            <div
              className={`flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 ${
                isActive !== "home"
                  ? "text-slate-300"
                  : "text-white bg-gradient-to-l from-blue-500"
              } cursor-pointer`}
              onClick={() => {
                setUserTabState("dashboard");
                setIsActive("home");
              }}
            >
              <button title="Dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                  />
                </svg>
              </button>
              <h1 className="sm:flex md:text-base text-sm hidden">Dashboard</h1>
            </div>
            <div
              className={`flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 ${
                isActive !== "management"
                  ? "text-slate-300"
                  : "text-white bg-gradient-to-l from-blue-500"
              } cursor-pointer`}
              onClick={() => {
                setUserTabState("usermgt");
                setIsActive("management");
              }}
            >
              <button title="User Management">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              </button>
              <h1 className="sm:flex md:text-base text-sm hidden">
                User Management
              </h1>
            </div>
            <div
              className={`flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 ${
                isActive !== "admin"
                  ? "text-slate-300"
                  : "text-white bg-gradient-to-l from-blue-500"
              } cursor-pointer`}
              onClick={() => {
                setUserTabState("admin");
                setIsActive("admin");
              }}
            >
              <button title="Admin">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                  />
                </svg>
              </button>
              <h1 className="sm:flex md:text-base text-sm hidden">Admin</h1>
            </div>

            {/* Spacer */}

            <div>
              {/* <p className='text-slate-300 my-4'>--------------</p> */}
            </div>

            <a
              href="https://hivegroupinc.ladesk.com/467619-Moolahmore-KB"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 text-slate-300 cursor-pointer">
                <button title="Knowledge Base">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>
                </button>
                <h1 className="sm:flex md:text-base text-sm hidden">
                  Knowledge Base
                </h1>
              </div>
            </a>
            <a
              href="https://moolahmore.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 text-slate-300 cursor-pointer">
                <button title="Terms & Conditions">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                </button>
                <h1 className="sm:flex md:text-base text-sm hidden">
                  Terms & Conditions
                </h1>
              </div>
            </a>
            <div className="flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 text-slate-300 cursor-pointer">
              <button title="User Guide">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>
              </button>
              <h1 className="sm:flex md:text-base text-sm hidden">
                User Guide
              </h1>
            </div>
            <div
              onClick={logOut}
              className="flex items-center sm:justify-start justify-center py-3 hover:text-blue-500 text-slate-300 cursor-pointer"
            >
              <button title="Log Out">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
              <h1 className="sm:flex md:text-base text-sm hidden">Log out</h1>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <h1 className="text-cyan-500 mb-4 md:text-sm text-xs">
              {server === "pre" && `Pre`}
            </h1>
            <h1 className="text-cyan-500 mb-4 md:text-sm text-xs">
              {server === "stage" && `Stage`}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
