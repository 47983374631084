import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Users/Login/LoginPage";
import Home from "./Home/Home";
import APACLogin from "./Users/Login/APACLogin";
import { HelperContextProvider } from "./helpers/GlobalHelpers";
import USLogin from "./Users/Login/USLogin";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "./helpers/ProtectedRoute";
import { DashboardContextProvider } from "./helpers/DashboardContext";
import AdminContainer from "./Home/Admin/AdminContainer";
import FallbackPage from "./Users/FallbackPage";

function App() {

  return (
    <DashboardContextProvider>
    <HelperContextProvider>
      <div className="max-h-screen w-screen bg-black">
        <Toaster position="top-center" reverseOrder={false}/>
        <Routes>
          <Route path="/" element={ <LoginPage /> }></Route>
          <Route path="/loginAPAC" element={ <APACLogin /> }></Route>
          <Route path="/loginAPAC-PROD" element={ <USLogin /> }></Route>
          <Route element={ <PrivateRoute /> }>
            <Route path="/home" element={ <Home /> } exact></Route>
            <Route path="/admin" element={ <AdminContainer /> } exact></Route>
          </Route>
          <Route path="*" element={ <FallbackPage /> }></Route>
        </Routes>
      </div>
    </HelperContextProvider>
    </DashboardContextProvider>
  );
}

export default App;
