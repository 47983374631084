import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined)
    }, 300)

    return (
        <div className='w-full flex items-center text-slate-100 md:mb-0 mb-2'>
            <h3 className='mr-2'>Search: </h3>
            <input type="text" name="" id="" className='bg-slate-800 border-0 border-b-2 border-blue-500   outline-blue-500 text-white w-40 md:w-60 py-1 px-2 rounded-md' placeholder={`${count} records listed`} value={value || ''} onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
                }}/>
        </div>
    )
}

