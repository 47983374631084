import React, { useContext, useState } from 'react'
import EmpAdminTable from '../../Components/Tables/EmpAdminTable'
import EmpGroupsTable from '../../Components/Tables/EmpGroupsTable'
import EmpSuspendedGroupsTable from '../../Components/Tables/EmpSuspendedGroupsTable'
import EmpSuspendedTable from '../../Components/Tables/EmpSuspendedTable'
import EmpUserTable from '../../Components/Tables/EmpUserTable'
import PopUpContext from '../../helpers/PopUpContext'

function Access() {

  const [accessTab, setAccessTab] = useState('users')
  const { setOpenNewAccess } = useContext(PopUpContext)

  return (
    <div className='w-full h-auto mt-4 mr-10'>
      <div className="w-auto h-full bg-slate-900 mr-4 rounded-md">
        <div className='w-full flex items-center  border-b border-slate-700 py-2'>
          <div className='w-full px-6 py-2 flex items-center justify-between'>
            <h1 className='text-slate-100 text-xl'>Access List</h1>
              <button className='flex items-center text-white bg-blue-600 hover:bg-blue-700 px-2 py-1 rounded-md' onClick={() => setOpenNewAccess(true)}>
                New 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
          </div>
        </div>
        <div className='w-full h-auto'>
          <div className='w-auto overflow-x-auto mt-2 ml-6 flex items-center text-slate-100 border-b border-slate-400 mr-6'>
            <div className={accessTab === 'users' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'}  onClick={() => setAccessTab('users')}>
                  <h2 className='text-base'>Users</h2>
            </div>
            <div className={accessTab === 'groups' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setAccessTab('groups')}>
                  <h2 className='text-base'>Groups</h2>
            </div>
            <div className={accessTab === 'admins' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'}  onClick={() => setAccessTab('admins')}>
                  <h2 className='text-base'>Admins</h2>
            </div>
            <div className={accessTab === 'suspended' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setAccessTab('suspended')}>
                  <h2 className='text-base'>Suspended</h2>
            </div>
            <div className={accessTab === 'suspendedGroups' ? 'px-4 py-2 cursor-pointer border-b-4 border-blue-500' : 'px-4 py-2 cursor-pointer hover:border-b-4 border-blue-500'} onClick={() => setAccessTab('suspendedGroups')}>
                  <h2 className='text-base'>Suspended Groups</h2>
            </div>
          </div>
          {accessTab === 'users' && <EmpUserTable />}
          {accessTab === 'admins' && <EmpAdminTable />}
          {accessTab === 'suspended' && <EmpSuspendedTable />}
          {accessTab === 'groups' && <EmpGroupsTable />}
          {accessTab === 'suspendedGroups' && <EmpSuspendedGroupsTable />}
        </div>
      </div>
    </div>
  )
}

export default Access