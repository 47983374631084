import React from 'react'

function TokenErrorNotification() {
  return (
    <div className='w-full flex items-center justify-center fixed'>
        <div className='mt-2 px-4 mx-2 max-w-96 h-28 bg-red-500 flex items-center justify-center rounded-lg'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-white mr-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
            <div>
              <h1 className='text-white md:text-lg text-sm'>Access Denied! Invalid or Expired Token</h1>
              <p className='text-white '>Please re-login</p>
            </div>
        </div>
    </div>
  )
}

export default TokenErrorNotification