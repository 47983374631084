import { mapKeys } from "lodash";
import React, { useContext } from "react";
import moment from "moment/moment";
import BarChartExpiry from "../../Components/Charts/BarChartExpiry";
import BarChartSubs from "../../Components/Charts/BarChartSubs";
import DoughnutChart from "../../Components/Charts/DoughnutChart";
import DoughnutChartSource from "../../Components/Charts/DoughnutChartSource";
import DBTable from "../../Components/Tables/DBTable";
import DashboardContext from "../../helpers/DashboardContext";
import ModalContext from "../../helpers/ModalContext";
import { motion } from "framer-motion";
import { useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import PopUpContext from "../../helpers/PopUpContext";
import HelpersContext from "../../helpers/GlobalHelpers";

function Dashboard({ handleUtype }) {
  const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const {
    dataSet,
    utype,
    source,
    chosenUtype,
    chosenDateFrom,
    setChosenDateFrom,
    chosenDateTo,
    setChosenDateTo,
    setChosenSource,
  } = useContext(DashboardContext);
  const {
    setIsModalOpen,
    setUserTypeData,
    setSourceTypeData,
    setChartType,
    setSubsTypeData,
    setExpData,
  } = useContext(ModalContext);
  const { setConfirmPWRelogin } = useContext(PopUpContext);
  const { URI, apiAuthURL } = useContext(HelpersContext);

  const subsChartData = {
    labels: chosenUtype === "all" ? ["Users", "Subscriber"] : [chosenUtype],
    datasets: [
      {
        data:
          chosenUtype === "all"
            ? [
                dataSet.filter((us) => us.userType === "users").length,
                dataSet.filter((su) => su.userType === "subscriber").length,
              ]
            : [dataSet.length],
        backgroundColor: [
          "#FFA1CF",
          "#47B5FF",
          "#C7F2A4",
          "#F96666",
          "#D0B8A8",
        ],
        borderColor: "black",
      },
    ],
  };

  const uniqueSource = dataSet
    .filter((nu) => nu !== null || 0 || "")
    .map((src) => src.source);
  const newUniqueSource = {};
  uniqueSource.forEach(
    (i) => (newUniqueSource[i] = (newUniqueSource[i] || 0) + 1)
  );
  delete newUniqueSource.null;

  const sourceChartData = {
    labels: Object.keys(newUniqueSource),
    datasets: [
      {
        data: Object.values(newUniqueSource),
        backgroundColor: [
          "#FD841F",
          "#FA7070",
          "#A084CA",
          "#7895B2",
          "#FFEBAD",
          "#4C0033",
          "#874C62",
        ],
        borderColor: "black",
      },
    ],
  };

  const uniqueCount = dataSet
    .filter((nu) => nu !== null || 0 || "")
    .map((subs) => subs.productId);
  const newUniqueCount = {};
  uniqueCount.forEach(
    (i) => (newUniqueCount[i] = (newUniqueCount[i] || 0) + 1)
  );
  delete newUniqueCount.null;
  const subsLength = Object.values(newUniqueCount).reduce((a, b) => a + b, 0);

  // let total = Object.values(newUniqueCount).reduce((acc,val) =>{
  //     acc += val
  //     return acc;
  // },0)
  let result = Object.keys(newUniqueCount).reduce((acc, key) => {
    let val = newUniqueCount[key];
    // acc[key] = (val/total*100).toFixed() + '%'
    acc[key] = val;
    return acc;
  }, {});

  const newKeys = mapKeys(newUniqueCount, (value, key) => {
    if (key === "freemium") return `Freemium (${result["freemium"]})`;
    if (key === "Basic Subscription Plan")
      return `Basic Subscription Plan (${result["Basic Subscription Plan"]})`;
    if (key === "Standard Subscription Plan")
      return `Standard Subscription Plan (${result["Standard Subscription Plan"]})`;
    if (key === "Enterprise Subscription Plan")
      return `Premium Plan (${result["Enterprise Subscription Plan"]})`;
    if (key === "mm_basic_plan.")
      return `Basic Plan (${result["mm_basic_plan."]})`;
    if (key === "mm_enterprise_plan.")
      return `Enterprise Plan (${result["mm_enterprise_plan."]})`;
    if (key === "mm_premium_plan.")
      return `Premium Plan (${result["mm_premium_plan."]})`;
    if (key === "mm_standard_plan.")
      return `Standard Plan (${result["mm_standard_plan."]})`;
    if (key === "trial") return `Trial (${result["trial"]})`;
    if (key === "complimentary")
      return `Complimentary (${result["complimentary"]})`;
    if (key === "trial_mm_basic_plan.")
      return `Trial Basic Plan (${result["trial_mm_basic_plan."]})`;
    if (key === "trial_mm_enterprise_plan.")
      return `Trial Enterprise Plan (${result["trial_mm_enterprise_plan."]})`;
    if (key === "trial_mm_premium_plan.")
      return `Trial Premium Plan (${result["trial_mm_premium_plan."]})`;
    if (key === "trial_mm_premium_plan.-1")
      return `Trial Premium Plan -1 (${result["trial_mm_premium_plan.-1"]})`;
    if (key === "trial_mm_standard_plan.")
      return `Trial Standard Plan (${result["trial_mm_standard_plan."]})`;
    if (key === "trial_mm_standard_plan.-1")
      return `Trial Standard Plan -1 (${result["trial_mm_standard_plan.-1"]})`;
    if (key === "Lifetime") return `Lifetime (${result["Lifetime"]})`;
    return key;
  });

  const subsBarData = {
    datasets: [
      {
        label: ["Subscriptions"],
        data: newKeys,
        backgroundColor: [
          "#FFA1CF",
          "#47B5FF",
          "#C7F2A4",
          "#F96666",
          "#D0B8A8",
          "#7D6E83",
          "#7895B2",
          "#FD841F",
          "#FA7070",
          "#FFEBAD",
          "#4C0033",
          "#874C62",
          "#3D8361",
          "#A084CA",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  const dates = dataSet
    .filter((nu) => nu !== null || 0 || "")
    .map((exp) => moment(exp.subscriptionExpiry).format("DD-MMM-YYYY"));
  const uniqueDates = dates.filter((inv) => inv !== "Invalid date");
  const newUniqueCountExp = {};
  uniqueDates.sort((a, b) => Date.parse(a) - Date.parse(b));
  uniqueDates.forEach(
    (i) => (newUniqueCountExp[i] = (newUniqueCountExp[i] || 0) + 1)
  );
  delete newUniqueCountExp["1/1/1970"];
  const expLength = Object.values(newUniqueCountExp).reduce((a, b) => a + b, 0);

  const newExpiry = mapKeys(newUniqueCountExp, (value, key) => {
    // let total = Object.values(newUniqueCountExp).reduce((acc,val) =>{
    // acc += val
    // return acc;
    // },0)

    // let perc = (value/total*100).toFixed() + '%'

    // return `${key} (${perc})`
    return `${key} (${value})`;
  });

  const expiryBarData = {
    labels: Object.keys(newExpiry),
    datasets: [
      {
        label: ["Expiry"],
        data: newExpiry,
        backgroundColor: [
          "#FFA1CF",
          "#47B5FF",
          "#C7F2A4",
          "#F96666",
          "#D0B8A8",
          "#7D6E83",
          "#7895B2",
          "#FD841F",
          "#FA7070",
          "#FFEBAD",
          "#4C0033",
          "#874C62",
          "#3D8361",
          "#A084CA",
        ].reverse(),
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    const fetchUtype = async () => {
      const response = await axios.post(`${URI}getSubsUtype?`, {
        accessToken: currentUserInfo?.accessToken,
      });
      if (!response.data._isError) {
      } else {
        if (response.data?.isAuthorized?.valid === false) {
          toast.error(response.data?.isAuthorized?.message);
          setConfirmPWRelogin(true);
        }
      }
    };
    fetchUtype();
  }, [currentUserInfo.accessToken, setConfirmPWRelogin, URI]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 400 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: -400 }}
      transition={{ duration: 1.2 }}
      className="w-auto max-h-screen overflow-y-scroll ml-4"
    >
      <div className="h-auto flex flex-col mb-44 ">
        <div className="pt-20">
          <h1 className="font-bold text-4xl text-blue-500">Dashboard</h1>
        </div>
        <div className="mt-16 mb-8 flex items-center text-slate-100">
          <img
            loading="lazy"
            src={`${apiAuthURL}/docz/getPicture/${currentUserInfo?.biometric_id}`}
            alt=""
            className="w-10 h-10 mr-6 rounded-full object-contain border-2 border-green-500"
          />
          <h2 className="mr-10 md:text-lg text-sm">
            {currentUserInfo?.fullname}
          </h2>
          <button className="text-xs bg-slate-700 py-1 px-5 rounded-sm mr-4">
            Profile
          </button>
        </div>

        <div className="w-auto h-full flex flex-col bg-slate-900 mr-4 rounded-md">
          {dataSet ? (
            <>
              <div className="w-full py-2 px-4 border-b border-slate-800">
                <h1 className="text-slate-100 text-2xl">Statistics</h1>
              </div>
              <div className="w-full flex md:flex-row items-center flex-col border-b border-slate-700">
                <div
                  className="md:w-1/2 w-full py-4 flex flex-col items-center justify-center md:border-r md:border-slate-700 md:border-b-0 border-b border-slate-700 cursor-pointer hover:bg-slate-800"
                  onClick={() => {
                    setUserTypeData(subsChartData);
                    setChartType(1);
                    setIsModalOpen(true);
                  }}
                >
                  <h3 className="text-slate-100 font-bold">
                    User Type {`(${dataSet.length})`}
                  </h3>
                  <DoughnutChart subsChartData={subsChartData} />
                </div>
                <div
                  className="md:w-1/2 w-full py-4 flex flex-col items-center justify-center hover:bg-slate-800 cursor-pointer"
                  onClick={() => {
                    setSourceTypeData(sourceChartData);
                    setChartType(2);
                    setIsModalOpen(true);
                  }}
                >
                  <h3 className="text-slate-100 font-bold">
                    Source{" "}
                    {`(${
                      dataSet.filter(
                        (src) => src.source !== null || "" || 0 || undefined
                      ).length
                    })`}
                  </h3>
                  <DoughnutChartSource sourceChartData={sourceChartData} />
                </div>
              </div>
              <div className="w-full py-2 px-4 border-b border-slate-700">
                <h1 className="text-slate-100 text-2xl">
                  Products {`&`} Subscription Expiry
                </h1>
              </div>
              <div className="w-full flex md:flex-row items-center flex-col mb-4 border-b border-slate-700">
                <div
                  className="md:w-1/2 w-full py-4 flex flex-col items-center justify-center md:border-r md:border-slate-700 md:border-b-0 border-b border-slate-700 hover:bg-slate-800 cursor-pointer"
                  onClick={() => {
                    setSubsTypeData(subsBarData);
                    setChartType(3);
                    setIsModalOpen(true);
                  }}
                >
                  <h3 className="text-slate-100 font-bold">
                    Subscriptions {`(${subsLength})`}
                  </h3>
                  <BarChartSubs subsBarData={subsBarData} />
                </div>
                <div
                  className="md:w-1/2 w-full py-4 flex flex-col items-center justify-center hover:bg-slate-800 cursor-pointer"
                  onClick={() => {
                    setExpData(expiryBarData);
                    setChartType(4);
                    setIsModalOpen(true);
                  }}
                >
                  <h3 className="text-slate-100 font-bold">
                    Expiry {`(${expLength})`}
                  </h3>
                  <BarChartExpiry expiryBarData={expiryBarData} />
                </div>
              </div>
              <div className="w-full h-full flex xl:flex-row flex-col items-center justify-center pb-2 md:px-4 text-sm border-b border-slate-800">
                <div className="flex sm:flex-row flex-col pl-4 lg:pl-0 xl:justify-start justify-between lg:w-1/2 w-full lg:mb-2 mb-2">
                  <div className="w-full lg:w-auto flex justify-between lg:justify-start items-center sm:mb-0 mb-2">
                    <label
                      htmlFor="chosenUtype"
                      className="text-slate-100 mr-2"
                    >
                      User Type:{" "}
                    </label>
                    <select
                      name="chosenUtype"
                      id=""
                      value={chosenUtype}
                      className="rounded-md lg:mr-0 xl:mr-4 mr-2 py-1"
                      onChange={handleUtype}
                    >
                      {utype?.map((u) => (
                        <option key={u} value={u}>
                          {u}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full lg:w-auto flex justify-between lg:justify-start items-center sm:mr-0 mr-4 lg:mr-0 lg:pr-0 pr-2">
                    <label
                      htmlFor="chosenSource"
                      className="text-slate-100 mr-2"
                    >
                      Source:{" "}
                    </label>
                    <select
                      name="chosenSource"
                      id=""
                      className="rounded-md py-1"
                      onChange={(e) => setChosenSource(e.target.value)}
                    >
                      {source?.map((s) => (
                        <option key={s} value={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="xl:w-1/2 w-full flex xl:flex-row flex-col items-center justify-start lg:mr-4 pl-4">
                  <div className="w-full flex sm:flex-row flex-col items-center justify-center lg:mb-2">
                    <div className="w-full lg:w-auto flex items-center justify-between lg:justify-start lg:mr-4 xl:mr-1 lg:mb-0 md:mb-0 mb-2 pr-2">
                      <label
                        htmlFor="chosenDateFrom"
                        className="text-slate-100 mr-2 md:text-sm text-xs"
                      >
                        Date from:{" "}
                      </label>
                      <input
                        type="date"
                        name="chosenDateFrom"
                        id=""
                        defaultValue={chosenDateFrom}
                        className="rounded-md text-center py-1"
                        onChange={(e) => setChosenDateFrom(e.target.value)}
                      />
                    </div>
                    <div className="w-full lg:w-auto flex justify-between lg:justify-start items-center lg:mr-0 pr-2">
                      <label
                        htmlFor="chooseDateTo"
                        className="text-slate-100 mr-2 md:text-sm text-xs"
                      >
                        Date to:{" "}
                      </label>
                      <input
                        type="date"
                        name="chooseDateTo"
                        id=""
                        defaultValue={chosenDateTo}
                        className="rounded-md text-center py-1"
                        onChange={(e) => setChosenDateTo(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className='w-full flex justify-end xl:mt-0 mt-2 lg:mb-2 pr-2'>
                                <button className='py-1 px-3 text-base bg-blue-500 text-slate-200 hover:bg-blue-700 rounded-md' onClick={() => fetchDataSet()}>Search</button>
                            </div> */}
                </div>
              </div>
              <div className="w-full py-4 px-4">
                <div className="flex flex-col items-center justify-between">
                  <div className="w-full">
                    <DBTable dataSet={dataSet} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-white">
                <h1>No data to show</h1>
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Dashboard;
