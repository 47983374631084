import React, { createContext, useState } from "react";


export const ModalContext = createContext(null)

export const ModalContextProvider = ({children}) => {
    
    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ chartType, setChartType ] = useState(0)
    const [ userTypeData, setUserTypeData ] = useState(null)
    const [ sourceTypeData, setSourceTypeData ] = useState(null)
    const [ subsTypeData, setSubsTypeData ] = useState(null)
    const [ expData, setExpData ] = useState(null)

    const value = {
        isModalOpen, setIsModalOpen,
        userTypeData, setUserTypeData,
        sourceTypeData, setSourceTypeData,
        chartType, setChartType,
        subsTypeData, setSubsTypeData,
        expData, setExpData
    }

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalContext
